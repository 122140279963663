import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Switch, message, Divider, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { SettingControlContainer } from './CustomStyled';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import HelpButton from '../elements/HelpButton';
import { toJS } from 'mobx';

const IFCSetting = ({ projectStore, projectSettingStore, uiStore, usersStore, objectQueryStore }) => {
  const { t } = useTranslation();
  const [IFCParams, setIFCParams] = useState(projectSettingStore.systemProjectSetting?.ifcSetting)
  const [showCompress, setShowCompress] = useState(false)
  const [form] = Form.useForm();
  const { Option } = Select;

  function updateKey(data) {
    var x = Object.assign(IFCParams, data)
    setIFCParams(x)
  }

  useEffect(() => {
    let fetch = async () => {
      // await projectStore.getProjectDetail(projectId)
      if (Object.keys(projectStore.projectDetail).length > 0) {
        let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
        let data = {}
        if (usersStore.currentUser?.id) {
          if (_systemProjectSetting.length > 0) {
            let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
            if (!_currentUserSystemSetting) return
            if (_currentUserSystemSetting.projectSetting && Object.keys(_currentUserSystemSetting.projectSetting).length > 1) {
              data = _currentUserSystemSetting.projectSetting
            } else {
              data = projectSettingStore.systemProjectSetting
            }
          } else {
            data = projectSettingStore.systemProjectSetting
          }
        } else {
          data = projectSettingStore.defaultSystemProjectSetting
        }
        if (projectStore.projectDetail?.metadata?.ifcSetting) {
          data = projectStore.projectDetail?.metadata;
        }
        form.setFieldsValue({
          terrainTileSize: data?.ifcSetting?.terrainTileSize || 500,
          maxLevel: data?.ifcSetting?.maxLevel || -1,
          maxTrianglesInFile: data?.ifcSetting?.maxTrianglesInFile || 2000000,
          scaleGeometricError: data?.ifcSetting?.scaleGeometricError || 1,
          combineObject: true || data?.ifcSetting?.combineObject,
          IFCLargeSegments: data?.ifcSetting?.IFCLargeSegments || 16,
          IFCSmallSegments: data?.ifcSetting?.IFCSmallSegments || 8,
          XMLAccuracy: data?.ifcSetting?.XMLAccuracy || 0.005,
          faceterDevSurface: data?.ifcSetting?.faceterDevSurface || 0.5,
          faceterDevNormal: data?.ifcSetting?.faceterDevNormal || 15,
          faceterGridRatio: data?.ifcSetting?.faceterGridRatio || 1,
          faceterMaxEdgeLength: data?.ifcSetting?.faceterMaxEdgeLength || 20,
          faceterMaxGrid: data?.ifcSetting?.faceterMaxGrid || 20,
          faceterMinUGrid: data?.ifcSetting?.faceterMinUGrid || 2,
          faceterMinVGrid: data?.ifcSetting?.faceterMinVGrid || 2,
          pointCloudEngine: data?.ifcSetting?.pointCloudEngine || 'xdEngine',
          pointCloudsCompression: data?.ifcSetting?.pointCloudsCompression || false,
          importer: data?.ifcSetting?.importer || 'ellipsoid',
          ifcimporter: 'rdf' || data?.ifcSetting?.ifcimporter || 'rdf',
          importFilter: checkImportFilter(data?.ifcSetting?.importFilter) ? data?.ifcSetting?.importFilter : 'none',
          // filterMethod: data?.ifcSetting?.filterMethod || 'import'
        })
        let _value = {
          terrainTileSize: data?.ifcSetting?.terrainTileSize,
          maxLevel: data?.ifcSetting?.maxLevel,
          maxTrianglesInFile: data?.ifcSetting?.maxTrianglesInFile,
          scaleGeometricError: data?.ifcSetting?.scaleGeometricError || 1,
          combineObject: true || data?.ifcSetting?.combineObject,
          IFCLargeSegments: data?.ifcSetting?.IFCLargeSegments,
          IFCSmallSegments: data?.ifcSetting?.IFCSmallSegments,
          XMLAccuracy: data?.ifcSetting?.XMLAccuracy,
          pointCloudEngine: data?.ifcSetting?.pointCloudEngine || 'xdEngine',
          pointCloudsCompression: data?.ifcSetting?.pointCloudsCompression || false,
          importer: data?.ifcSetting?.importer || 'ellipsoid',
          ifcimporter: 'rdf' || data?.ifcSetting?.ifcimporter || 'rdf',
          importFilter: checkImportFilter(data?.ifcSetting?.importFilter) ? data?.ifcSetting?.importFilter : 'none',
          // filterMethod: data?.ifcSetting?.filterMethod || 'import'
          filterMethod: 'import'
        }

        if (data?.ifcSetting?.compression) {
          setShowCompress(true)
          form.setFieldsValue({
            compress: true,
            compressionLevel: data?.ifcSetting?.compression?.compressionLevel || 7,
            posQuantizationBits: data?.ifcSetting?.compression?.posQuantizationBits || 16,
            normalsQuantizationBits: data?.ifcSetting?.compression?.normalsQuantizationBits || 8,
            texcoordsQuantizationBits: data?.ifcSetting?.compression?.texcoordsQuantizationBits || 8,
            colorQuantizationBits: data?.ifcSetting?.compression?.colorQuantizationBits || 8,
            genericQuantizationBit: data?.ifcSetting?.compression?.genericQuantizationBit || 16
          })

          _value.compressionLevel = data?.ifcSetting?.compression?.compressionLevel || 7
          _value.posQuantizationBits = data?.ifcSetting?.compression?.posQuantizationBits || 16
          _value.normalsQuantizationBits = data?.ifcSetting?.compression?.normalsQuantizationBits || 8
          _value.texcoordsQuantizationBits = data?.ifcSetting?.compression?.texcoordsQuantizationBits || 8
          _value.colorQuantizationBits = data?.ifcSetting?.compression?.colorQuantizationBits || 8
          _value.genericQuantizationBit = data?.ifcSetting?.compression?.genericQuantizationBit || 16
        }

        setIFCParams(_value)
      }
    }

    if (uiStore.showIFCSetting) {
      fetch()
    }
  }, [uiStore.showIFCSetting])

  const handleSaveIfcSetting = () => {
    form.validateFields().then(res => {
      let resdata = {
        IFCLargeSegments: res.IFCLargeSegments,
        IFCSmallSegments: res.IFCSmallSegments,
        XMLAccuracy: res.XMLAccuracy,
        combineObject: true || res.combineObject,
        maxLevel: res.maxLevel,
        maxTrianglesInFile: res.maxTrianglesInFile,
        scaleGeometricError: res.scaleGeometricError || 1,
        terrainTileSize: res.terrainTileSize,
        faceterDevSurface: res?.faceterDevSurface || 0.5,
        faceterDevNormal: res?.faceterDevNormal || 15,
        faceterGridRatio: res?.faceterGridRatio || 1,
        faceterMaxEdgeLength: res?.faceterMaxEdgeLength || 20,
        faceterMaxGrid: res?.faceterMaxGrid || 20,
        faceterMinUGrid: res?.faceterMinUGrid || 2,
        faceterMinVGrid: res?.faceterMinVGrid || 2,
        pointCloudEngine: res.pointCloudEngine || 'xdEngine',
        pointCloudsCompression: res?.pointCloudsCompression || false,
        importer: res.importer ||'ellipsoid',
        ifcimporter: 'rdf' || res.ifcimporter,
        importFilter: res.importFilter,
        filterMethod: 'import'
        // filterMethod: res.filterMethod || 'import'
      }
      if (res.compress) {
        resdata.compression = {
          compressionLevel: res.compressionLevel,
          posQuantizationBits: res.posQuantizationBits,
          genericQuantizationBit: res.genericQuantizationBit,
          normalsQuantizationBits: res.normalsQuantizationBits,
          texcoordsQuantizationBits: res.texcoordsQuantizationBits,
          colorQuantizationBits: res.colorQuantizationBits
        }
      } else {
        resdata.compression = undefined
      }

      projectSettingStore.setIfcSetting(resdata)
      if (usersStore.currentUser?.id) {
        let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
        projectStore.setLoadingProgress(true)
        projectStore.updateProjectMetadata({ metadata }).then(res => {
          projectStore.setLoadingProgress(false)
          uiStore.setShowIFCSetting(false)
          projectStore.setCleanMode(false)
          message.success(t('updated-successfully'))
        })
          .catch(err => {
            projectStore.setLoadingProgress(false)
            message.error(t('updated-failed'))
          })
      } else {
        projectStore.setLoadingProgress(false)
        uiStore.setShowIFCSetting(false)
        projectStore.setCleanMode(false)
        message.success(t('updated-successfully'))
      }
    })
  }

  const options = useMemo(() => {
    const queries = !objectQueryStore?.listObjectsQuery ? [] :
      objectQueryStore?.listObjectsQuery.map(c => {
        return {
          label: c.name,
          value: c.id
        }
      })
    queries.unshift({
      label: 'none',
      value: 'none'
    })
    return queries;
  }, [objectQueryStore?.listObjectsQuery])
  const optionFilterMethod = [
    {
      label: 'Import',
      value: 'import'
    },
    {
      label: 'Hide',
      value: 'hide'
    }
  ]
  const checkImportFilter = (id) => {
    const isExist = objectQueryStore?.listObjectsQuery.find(c => c.id === id)
    return isExist
  }

  return (
    <SettingControlContainer style={{ padding: 0 }}>
      <Scrollbars autoHeight autoHeightMin={100} autoHeightMax="calc(100vh - 36px)">
        <div style={{ padding: '10px' }}>
          <div className='help-btn-wrap'>
            <HelpButton helppage={"system_settings_3DTiling"} />
          </div>
          <Form form={form} layout="vertical">
            <p><strong>{t("import-settings")}</strong></p>
            <Form.Item
              name='terrainTileSize'
              label={t('terrain-tile-size')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ terrainTileSize: v })
              }}>
              <InputNumber placeholder={t('set-vertical-correction')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item
              name='maxTrianglesInFile'
              label={t('max-triangles')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ maxTrianglesInFile: v })
              }}>
              <InputNumber placeholder={t('max-triangles')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item
              name='maxLevel'
              label={t('max-levels')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ maxLevel: v })
              }}>
              <InputNumber placeholder={t('max-levels')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='scaleGeometricError' label={t('geometric-error-scale')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ scaleGeometricError: v })
              }}>
              <InputNumber placeholder={t('geometric-error-scale')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='IFCLargeSegments' label={t('ifc-large-segments')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ IFCLargeSegments: v })
              }}>
              <InputNumber placeholder={t('ifc-large-segments')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='IFCSmallSegments' label={t('ifc-small-segments')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ IFCSmallSegments: v })
              }}>
              <InputNumber placeholder={t('ifc-small-segments')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='XMLAccuracy' label={t('xml-accuracy')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ XMLAccuracy: v })
              }}>
              <InputNumber placeholder={t('xml-accuracy')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            {/* dwg,dgn */}
            <Form.Item name='faceterDevSurface' label={t('FaceterDevSurface')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterDevSurface: v })
              }}>
              <InputNumber placeholder={t('faceterDevSurface')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='faceterDevNormal' label={t('FaceterDevNormal')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterDevNormal: v })
              }}>
              <InputNumber placeholder={t('faceterDevNormal')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='faceterGridRatio' label={t('FaceterGridRatio')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterGridRatio: v })
              }}>
              <InputNumber placeholder={t('faceterGridRatio')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='faceterMaxEdgeLength' label={t('FaceterMaxEdgeLength')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterMaxEdgeLength: v })
              }}>
              <InputNumber placeholder={t('faceterMaxEdgeLength')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='faceterMaxGrid' label={t('FaceterMaxGrid')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterMaxGrid: v })
              }}>
              <InputNumber placeholder={t('faceterMaxGrid')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='faceterMinUGrid' label={t('FaceterMinUGrid')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterMinUGrid: v })
              }}>
              <InputNumber placeholder={t('faceterMinUGrid')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item name='faceterMinVGrid' label={t('FaceterMinVGrid')}
              onChange={(v) => {
                if (isNaN(v)) v = -1
                updateKey({ faceterMinVGrid: v })
              }}>
              <InputNumber placeholder={t('faceterMinVGrid')} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
            {/* dwg,dgn */}
            <Form.Item
              label={('BIM Models: Engine')}
              name="ifcimporter">
              <Select
                onChange={v => updateKey({ ifcimporter: v })}
              >
                <Option value="rdf">Default</Option>
                {/* <Option value="oda">ODA</Option> */}
              </Select>
            </Form.Item>
            <Form.Item
              name='compress'
              label={('BIM Models: Compress')}
              valuePropName="checked"
              className="inlinelayout"
            >
              <Switch
                checkedChildren={t('commons.on')}
                unCheckedChildren={t('commons.off')}
                style={{ marginLeft: '5px' }}
                onChange={(v) => {
                  setShowCompress(v)
                }}
              />
            </Form.Item>
            <Form.Item
              label={('Point cloud: Engine')}
              name="pointCloudEngine">
              <Select
                onChange={v => updateKey({ importer: v })}
              >
                <Option value="cesiumION">Cesium ION</Option>
                <Option value="xdEngine">XD Engine</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='pointCloudsCompression'
              label={('Point cloud: Compression')}
              valuePropName="checked"
              onChange={(v) => {
                if (isNaN(v)) v = false
                updateKey({ combineObject: v })
              }}
              className="inlinelayout"
            >
              <Switch
                checkedChildren={t('commons.on')}
                unCheckedChildren={t('commons.off')}
                style={{ marginLeft: '5px' }}
              />
            </Form.Item>
            <Form.Item
              label={t('Import mode')}
              name="importer">
              <Select
                onChange={v => updateKey({ importer: v })}
              >
                <Option value="speed">Plane</Option>
                <Option value="ellipsoid">Ellipsoid</Option>
              </Select>
            </Form.Item>
            
            <Form.Item
              label={t('Import filter')}
              name="importFilter">
              <Select
                onChange={v => updateKey({ importFilter: v })}
                showSearch
                options={options}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
              />
            </Form.Item>
            {/* <Form.Item
              name='combineObject'
              label={t('combine-objects')}
              valuePropName="checked"
              onChange={(v) => {
                if (isNaN(v)) v = false
                updateKey({ combineObject: v })
              }}
              className="inlinelayout"
            >
              <Switch
                checkedChildren={t('commons.on')}
                unCheckedChildren={t('commons.off')}
                style={{ marginLeft: '5px' }}
              />
            </Form.Item> */}
            
            {showCompress && <div>
              <Form.Item
                name='compressionLevel'
                label={t('CompressionLevel (1-10)')}
                rules={[{ required: true }]}
                initialValue={7}
              >
                <InputNumber className='hw-full' ini={7} min={1} max={10} step={1} />
              </Form.Item>
              <Form.Item
                name='posQuantizationBits'
                label={t('PosQuantizationBits (1-32)')}
                rules={[{ required: true }]}
                initialValue={16}
              >
                <InputNumber className='hw-full' min={1} max={32} step={1} />
              </Form.Item>
              <Form.Item
                name='normalsQuantizationBits'
                label={t('NormalsQuantizationBits (1-32)')}
                rules={[{ required: true }]}
                initialValue={8}
              >
                <InputNumber className='hw-full' min={1} max={32} step={1} />
              </Form.Item>
              <Form.Item
                name='texcoordsQuantizationBits'
                label={t('TexcoordsQuantizationBits (1-32)')}
                rules={[{ required: true }]}
                initialValue={8}
              >
                <InputNumber className='hw-full' min={1} max={32} step={1} />
              </Form.Item>
              <Form.Item
                name='colorQuantizationBits'
                label={t('ColorQuantizationBits (1-32)')}
                rules={[{ required: true }]}
                initialValue={8}
              >
                <InputNumber className='hw-full' min={1} max={32} step={1} />
              </Form.Item>
              <Form.Item
                name='genericQuantizationBit'
                label={t('GenericQuantizationBit (1-32)')}
                rules={[{ required: true }]}
                initialValue={16}
              >
                <InputNumber className='hw-full' min={1} max={32} step={1} style={{ marginBottom: '20px' }} />
              </Form.Item>
            </div>}
            <Button
              type="default"
              icon={<CloseOutlined />}
              onClick={() => {
                uiStore.setShowIFCSetting(false)
                projectStore.setCleanMode(false)
              }}>
              {t('commons.cancel')}
            </Button>
            <Button
              icon={<SaveOutlined />}
              style={{ minWidth: '90px', marginLeft: 5 }}
              type="primary"
              onClick={() => handleSaveIfcSetting()}>
              {t('commons.save')}
            </Button>
          </Form>
        </div>
      </Scrollbars>
    </SettingControlContainer >
  )
}
export default inject('projectStore', 'uiStore', 'projectSettingStore', 'usersStore', 'objectQueryStore')(observer(IFCSetting))
