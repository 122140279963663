import Utils from '../utils'
import moment from 'moment'
import axios from 'axios'
import { cesiumToken, apiNcWmsUrl ,siteMode ,domain } from '../config'
import { clickPoint, mergeEditModelOnPlane, invXYZDialog, getXYZDialog, expandBoundingBox, cnvECEFtoENU, isIFCEllipsoid, isCloudPointXDEnginePlane, isCloudPointXDEngineEllipsoid, isCloudPointCesiumION, isE57XDEnginePlane, isE57XDEngineEllipsoid, isXMLEllipsoid, isIFCPlane } from '../components/helper/CesiumUtils'
import { Cesium3DTileFeature, ClippingPlane, Plane, Cartesian3, Transforms, Matrix4, Math as CesiumMath, IonResource } from 'cesium'
import { ProjectRequest } from '../requests';
import { t } from 'i18next';
import * as turf from "@turf/turf";
import uuid from 'uuid'
import TreeUtils from '../tree-utils'
import licenseStore from '../stores/licenseStore'
import organizationStore from '../stores/organizationStore'
import projectStore from '../stores/projectStore'
import { toJS } from 'mobx'
import { Buffer as pBuffer } from 'buffer';
import AWS from 'aws-sdk';
import _ from 'lodash';

export const s3ParseUrl = (url) => {
  var _decodedUrl = decodeURIComponent(url);

  var _result = null;

  // http://s3.amazonaws.com/bucket/key1/key2
  var _match = _decodedUrl.match(/^https?:\/\/s3.amazonaws.com\/([^\/]+)\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[1],
      key: _match[2],
      region: '',
    };
  }

  // http://s3-aws-region.amazonaws.com/bucket/key1/key2
  _match = _decodedUrl.match(/^https?:\/\/s3-([^.]+).amazonaws.com\/([^\/]+)\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[2],
      key: _match[3],
      region: _match[1],
    };
  }

  // http://bucket.s3.amazonaws.com/key1/key2
  _match = _decodedUrl.match(/^https?:\/\/([^.]+).s3.amazonaws.com\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[1],
      key: _match[2],
      region: '',
    };
  }

  // http://bucket.s3-aws-region.amazonaws.com/key1/key2 or,
  // http://bucket.s3.aws-region.amazonaws.com/key1/key2
  _match = _decodedUrl.match(/^https?:\/\/([^.]+).(?:s3-|s3\.)([^.]+).amazonaws.com\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[1],
      key: _match[3],
      region: _match[2],
    };
  }

  return _result;
}

export const saveProjectCurrentViewpoint = (currentUserId, viewer, timeSlider, modelHiden) => {
  if (!currentUserId) return
  if (projectStore.projectDetail.metadata && Array.isArray(projectStore.projectDetail.metadata.cameraPosition) && viewer.current?.cesiumElement) {
    let _cameraPosition = projectStore.projectDetail.metadata.cameraPosition.find(x => x.userId === currentUserId.toString())
    if (_cameraPosition && _cameraPosition.position) {
      const dist = Cartesian3.distance(_cameraPosition.position, viewer.current.cesiumElement.camera.position);
      if (dist < 10) return
    }
  }
  if (viewer.current?.cesiumElement && projectStore.projectDetail?.metadata) {
    let camera = viewer.current.cesiumElement.camera
    let camData = {}
    camData.userId = currentUserId
    camData.duration = 0
    camData.position = camera.position.clone()
    camData.direction = camera.direction.clone()
    camData.up = camera.up.clone()
    camData.orientation = {
      heading: viewer.current.cesiumElement.camera.heading,
      pitch: viewer.current.cesiumElement.camera.pitch,
      roll: viewer.current.cesiumElement.camera.roll
    }

    let _projectMetadata = projectStore.projectDetail.metadata
    if (_projectMetadata && _projectMetadata.cameraPosition && _projectMetadata.cameraPosition.length > 0) {
      const objIndex = _projectMetadata.cameraPosition.findIndex(obj => obj.userId === currentUserId);
      if (objIndex > -1) {
        _projectMetadata.cameraPosition[objIndex].position = camData.position
        _projectMetadata.cameraPosition[objIndex].direction = camData.direction
        _projectMetadata.cameraPosition[objIndex].up = camData.up
        _projectMetadata.cameraPosition[objIndex].orientation = camData.orientation
      } else {
        _projectMetadata.cameraPosition.push(camData)
      }
    } else {
      _projectMetadata.cameraPosition = [camData]
    }
    _projectMetadata[`selectedBaselayer${currentUserId}`] = viewer.current.cesiumElement.baseLayerPicker.viewModel.selectedImagery.name
    // _projectMetadata.timeSlider = timeSlider
    // _projectMetadata.modelHiden = modelHiden
    // _projectMetadata.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
    let newData = {
      metadata: _projectMetadata
    }

    projectStore.updateProjectData(newData).then((res) => {
      projectStore.projectDetail.metadata = res.metadata
    }).catch(err => {

    })
  }
}

export const saveCurrentViewpoint = (currentUserId, viewer, projectStore) => {
  if (!currentUserId) return;
  if (viewer) {
    let camera = viewer.camera;
    let camData = {};
    camData.userId = currentUserId;
    camData.duration = 0;
    camData.position = camera.position.clone();
    camData.direction = camera.direction.clone();
    camData.up = camera.up.clone();
    camData.orientation = {
      heading: viewer.camera.heading,
      pitch: viewer.camera.pitch,
      roll: viewer.camera.roll
    };
    projectStore.setPreviousCameraPosition([camData]);
  }
}

export const gotoUserViewpoint = (cameraPosition, currentUserId, viewer) => {
  let _camPosition = cameraPosition
  if (_camPosition && _camPosition.length > 0) {
    let _camuser = _camPosition.find(obj => obj.userId === currentUserId)
    if (_camuser) {
      let flyOption = {}
      if (_camuser.position) {
        let destination = new Cartesian3(
          _camuser.position.x,
          _camuser.position.y,
          _camuser.position.z
        )
        flyOption.destination = destination
      }
      flyOption.orientation = _camuser.orientation

      viewer.camera.setView(flyOption)
      projectStore.setPreviousCameraPosition([]);
    }
  }
}
export const getMimeOfFile = (filename) => {
  const ext = filename.split('.').pop()?.toLowerCase();
  let mime = ''
  switch (ext) {
    case 'pdf':
      mime = 'application/pdf'
      break;
    case 'png':
      mime = 'image/png'
      break;
    case 'jpe':
    case 'jpeg':
    case 'jpg':
      mime = 'image/jpeg'
      break;
    case 'gif':
      mime = 'image/gif'
      break;
    case 'json':
      mime = 'application/json'
      break;
    default:
      mime = 'application/octet-stream'
      break;
  }
  return mime
}
export const getOriginPublicJsonLink = async (projectStore, token, projectId, hash, modelName) => {
  let params = {
    projectId: projectId,
    hash: hash,
    modelName: modelName,
  }
  let jsonlink = ''
  await projectStore.getPublicJson(params, token).then(async response => {
    if (response) {
      if (response.status === 200) {
        jsonlink = (await Utils.getTileSetUrlFromModel(response.data))?.tileSetUrl
      }
    }
  }).catch((error) => console.log(error))
  return jsonlink
}

/**
 * Get topic has 4D timestamp
 * @param {*} schedulingStore 
 * @param {*} topics list array topic location
 */
export const getTopic4D = (schedulingStore, topics) => {
  let topics4D = []
  topics.forEach(topic => {
    const { timestamp4D } = topic
    if (timestamp4D) {
      const endDateTimestamp = moment(Number(timestamp4D)).format('YYYY-MM-DD HH:mm:ss')
      if (schedulingStore.currentViewingTime.isBefore(endDateTimestamp)) {
        topics4D.push(topic.id)
      }
    }
  })
  return topics4D
}

const processModel = async (model, projectStore) => {
  // Update Project tilesetdata and Model data return current model
  let rsp_model = await updateProjectModel(model.project?._id ? model.project : { _id: model.project }, model)
  let _project = rsp_model.project

  // merge All model
  if (_project.tilesetData && _project.tilesetData.RefPoint && _project.tilesetData.coordinateSystem && (!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre')) {
    let _model3ds = _project.model3DS.map(c => {
      let u = { ...c }
      if (c.id === model.id) {
        u.newUpdate = true
      }
      return u;
    })
    let modelmerge = await mergeAllModels(
      _model3ds,
      Cartesian3.fromDegrees(_project.tilesetData.RefPoint[1], _project.tilesetData.RefPoint[0], _project.tilesetData.RefPoint[2]),
      _project.tilesetData.refLocalProject ? Cartesian3.fromArray(_project.tilesetData.refLocalProject) : false,
      _project.tilesetData.georeferenced !== undefined ? _project.tilesetData.georeferenced : false,
      _project.headingRotation,
      _project.tilesetData.coordinateSystem.code,
      _project.elevationSystem ? _project.elevationSystem : 'None'
    );
    _project.model3DS = modelmerge // reassign list model to project
  }

  // update project TreeData
  let _treeData = await updateProjectTreeData(_project._id, model, projectStore.projectDetail.treeData)
  _project.treeData = _treeData

  /**Update FE projectStore.ProjectDetail */
  await projectStore.updateProjectRefPoint(_project)

  projectStore.setReRenderModel(model);
  // effect projectStore.modelList only load model no model.ref => incorrect
  // after merge all models => position of model change => need set setNewModelId to effect updatePlaneMatix
  //projectStore.setCurrentModelId(false);
  //projectStore.setNewModelId(model._id);
}

/**
 * Wait process ion (Monitor the tiling status)
 * @param {*} model 
 * @param {*} projectStore 
 */
export const waitUntilIonReady = async (processingId, setProcessingId, model, setProgressFileOnS3Ion, projectStore, cancelProcessing) => {
  return new Promise(async (resolve, reject) => {
    if (!model.data.ionAssetId || cancelProcessing) resolve(false)
    let assetId = model.data.ionAssetId
    const waitUntilReady = async () => {
      axios({
        method: 'get',
        url: `https://api.cesium.com/v1/assets/${assetId}`,
        timeout: 0,
        headers: {
          Authorization: `Bearer ${cesiumToken}`,
        },
      }).then(async r => {
        let status = r.data.status
        if (status === 'COMPLETE') {
          setProgressFileOnS3Ion({ done: r.data.percentComplete, status })
          let afterUpdateModel = await projectStore.updateModelStatus(model._id, { ionProgressing: 100, ionStatus: status, progressStatus: status });
          afterUpdateModel.ref = false;
          //const temp = projectStore.modelList?.length > 0? projectStore.modelList.map(m => m?._id === model._id? afterUpdateModel : m) : [afterUpdateModel];
          //projectStore.setModelList(temp) // call useEffect projectStore.modelList in projectDetailPage reload TilesetByModel
          await processModel({ ...afterUpdateModel }, projectStore)
          resolve({ size: r.data.bytes, done: r.data.percentComplete, status })
        } else if (status === 'DATA_ERROR') {
          setProgressFileOnS3Ion({ message: 'Data source is invalid. Please review data compatibility.', done: 0, status })
          resolve(false)
        } else if (status === 'ERROR') {
          setProgressFileOnS3Ion({ message: 'Internal error processing data. Please contact support@xd-visuals.fi.', done: 0, status })
          resolve(false)
        } else {
          if (status === 'NOT_STARTED') {
            setProgressFileOnS3Ion({ message: t('initializing-tiling-pipeline'), done: 0, status })
          } else {// IN_PROGRESS
            setProgressFileOnS3Ion({ message: t('tiling'), done: r.data.percentComplete, status })
          }
          processingId = setTimeout(waitUntilReady, 5000)
          setProcessingId(processingId)
        }
      }).catch(err => {
        console.log('Error: waitUntilIonReady')
        resolve(false)
      })
    }
    await waitUntilReady()
  })
}

export const checkModelIsReady = async (models, projectStore) => {
  return new Promise(async (resolve, reject) => {
    const isModelReady = (model) =>
      new Promise(async (resolve, reject) => {
        if (model.data?.srcTileset) {
          await projectStore.updateModelStatus(model._id, { ionProgressing: 100, ionStatus: 'COMPLETE', progressStatus: 'COMPLETE' });
          resolve(true);
          return;
        }
        let assetId = model.data.ionAssetId;
        axios({
          method: 'get',
          url: `https://api.cesium.com/v1/assets/${assetId}`,
          timeout: 0,
          headers: {
            Authorization: `Bearer ${cesiumToken}`,
          },
        }).then(async res => {
          let status = res.data.status;
          let percentComplete = res.data.percentComplete;
          if (status === 'COMPLETE') {
            await projectStore.updateModelStatus(model._id, { ionProgressing: 100, ionStatus: status, progressStatus: status });
            resolve(true);
          } else {
            await projectStore.updateModelStatus(model._id, { ionProgressing: percentComplete, ionStatus: status, progressStatus: status });
            resolve(false);
          }
        }).catch(err => {
          if (err.status === 404 && err.data.code === "ResourceNotFound") {
            projectStore.updateModelStatus(model._id, { ionProgressing: 0, ionStatus: 'ERROR', progressStatus: 'ERROR' });
            return resolve(true)
          }
          resolve(false)
        })
      })

    let output = [];
    next();
    function next(index = 0) {
      isModelReady(models[index]).then(res => {
        if (!res) output.push(models[index]);
        if (index === models.length - 1) {
          return resolve(output)
        }
        next(index + 1);
      })
    }
  });
}

export const waitUntilNcWmsReady = async (datasetId) => {
  return new Promise(async (resolve, reject) => {
    const waitUntilReady = async () => {
      await axios({
        method: 'GET',
        url: `${apiNcWmsUrl}/admin/datasetStatus`,
        params: {
          dataset: datasetId
        }
      }).then(r => {
        const status = r.data
        if (status.includes('READY')) {
          resolve(true)
        } else if (status.includes('LOADING')) {
          setTimeout(waitUntilReady, 5000)
        } else if (status.includes('ERROR')) {
          reject(false)
        }
      }).catch(error => {
        reject(error)
      })
    }
    await waitUntilReady()
  })
}

export const queuePromise = (all, projectStore) => {
  return new Promise((resolve, reject) => {
    const output = [];
    next()
    function next(index = 0) {
      all[index]().finally(res => {
        projectStore.setLoadingProgress(true);
        output.push(res);
        if (index === all.length - 1) {
          projectStore.setLoadingProgress(false);
          return resolve(output)
        }
        next(index + 1);
      })
    }
  })
}

//get model AttrData
export const getModelAttrData = (viewer, clickPosition, projectStore) => {
  let x = clickPoint(viewer, clickPosition)
  if (!x.pickedObject || !x.pickedObject.content || !x.pickedObject.content.tileset) return null
  const model = projectStore.findModelByUrl(x.pickedObject.content.tileset?.resource?.url)
  if (model) {
    if (model.type === 'ifc' || model.type === 'landxml' || model.type === 'landxmlBackground' || model.type === 'cad') {
      if (!(x.pickedObject instanceof Cesium3DTileFeature)) return null
      const GUID = x.pickedObject?.getProperty("GUID");
      if (!GUID || GUID === 'None') return null
      return {
        model3D: model._id,
        pTitle: model.name,
        GUID
      };
    } else {
      return {
        pTitle: model.name,
        pKey: model._id,
        model3D: model._id,
      };
    }
  }
}

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Check panoramic 360 degree image
 * @param {*} currentHeight 
 * @param {*} currentWidth 
 * @param {*} imgSource 
 * @returns 
 */
export const isFullPanorama = (currentHeight, currentWidth, imgSource) => {
  let proportionThreshold = 0.01, minimumHeight = 1080
  let proportion = currentHeight / currentWidth;
  return proportion >= 0.5 - proportionThreshold &&
    proportion <= 0.5 + proportionThreshold &&
    imgSource.height >= minimumHeight;
}

/**
 * Check Partial Panorama
 * @param {*} currentHeight 
 * @param {*} currentWidth 
 * @param {*} imgSource 
 * @returns 
 */
export const isPartialPanorama = (currentHeight, currentWidth, imgSource) => {
  let minimumWidth = 2000
  return currentHeight / currentWidth <= 0.5 &&
    imgSource.width >= minimumWidth;
}

export const loadImage = async (imageUrl) => {
  let img;
  const imageLoadPromise = new Promise(resolve => {
    img = new Image();
    img.onload = resolve;
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;
  });

  await imageLoadPromise;
  return img;
}

/**
 * 
 * @param {*} points type array [[x, y, z]...]
 * @returns 
 */
export const createClippingPlaneHole = (points_) => {
  if (points_[0][0] !== points_[points_.length - 1][0] && points_[0][1] !== points_[points_.length - 1][1]) {
    points_.push(points_[0]) //if not closed polygon then add closed point
  }

  if (turf.booleanClockwise(turf.lineString(points_))) {
    points_ = points_.reverse();
  }

  let points = [];
  for (let i = 0; i < points_.length - 1; i++) {
    points.push(new Cartesian3(points_[i][0], points_[i][1], points_[i][2]))
  }
  let pointsLength = points.length;
  let clippingPlanes = [];
  for (var i = 0; i < pointsLength; ++i) {
    var nextIndex = (i + 1) % pointsLength;
    var midpoint = Cartesian3.add(points[i], points[nextIndex], new Cartesian3());
    midpoint = Cartesian3.multiplyByScalar(midpoint, 0.5, midpoint);

    var up = Cartesian3.normalize(midpoint, new Cartesian3());
    var right = Cartesian3.subtract(points[nextIndex], midpoint, new Cartesian3());
    right = Cartesian3.normalize(right, right);

    var normal = Cartesian3.cross(right, up, new Cartesian3());
    normal = Cartesian3.normalize(normal, normal);

    // Compute distance by pretending the plane is at the origin
    var originCenteredPlane = new Plane(normal, 0.0);
    var distance = Plane.getPointDistance(originCenteredPlane, midpoint);

    clippingPlanes.push(new ClippingPlane(normal, distance));
  }

  return clippingPlanes;
}

export const getInverseTransform = (tileSet) => {
  if (tileSet._url && tileSet._url.includes('tile.googleapis.com')) {
    return Matrix4.inverse(tileSet._initialClippingPlanesOriginMatrix, new Matrix4())
  }
  let transform
  let tmp = tileSet.root.transform
  if ((tmp && tmp.equals(Matrix4.IDENTITY)) || !tmp) {
    // If root.transform does not exist, the origin of the 3DTiles becomes boundingSphere.center
    transform = Transforms.eastNorthUpToFixedFrame(tileSet.boundingSphere.center)
  } else {
    transform = Matrix4.fromArray(tileSet.root.transform)
  }
  return Matrix4.inverseTransformation(transform, new Matrix4())
}

export const addTreeNode = (child, treedata, selectedKey) => {
  if (treedata && treedata.length) {
    let toNode;
    const node = TreeUtils.searchTreeNode(treedata, 'key', selectedKey);
    if (selectedKey && node) {
      node.children = node.children && node.children.length ? node.children : [];
      toNode = node.children;
      //inherit access right from its parent
      // child.accesscontrols = node?.accesscontrols || [];
    } else {
      toNode = treedata;
    }
    toNode.push(child);
  } else {
    treedata = [child];
  }

  return treedata;
}

export const mergeAllModels = async (modelList, refProject, refLocalProject, georeferenced, headingRotation, epsg, elevation) => {
  let toUpdate = []
  const _resultMergeAllModel = await Promise.all(modelList.map(async (item) => {
    // if model is ellipsoid, clound point cesiumION, 3DTiles and I3S,CityGML,WMS,WFS,Global terrain, Global buildings,Terrain,GeoTIFF,GeoJSON,KML, KMZ then not merge
    const isValidExtension = ['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.txt', '.kmz', '.kml', '.tiff', '.tif', '.geojson'];

    if (item.data?.saveMatrix?.xyzLocal && item.data.ext && !isValidExtension.includes(item.data.ext.toLowerCase()) && !isXMLEllipsoid(item) && !isIFCEllipsoid(item) && !isCloudPointXDEngineEllipsoid(item) && !isCloudPointCesiumION(item) && !isE57XDEngineEllipsoid(item)) {
      let xyzLocal1 = Cartesian3.fromArray(item.data.saveMatrix.xyzLocal);
      let posCurrent1 = new Cartesian3();
      let localOrg = item.crs.LocalOrg || [0, 0, 0];
      let modelcenter = item.crs.ModelCenter || [0, 0, 0];

      if (!refLocalProject) {
        refLocalProject = Cartesian3.fromArray(localOrg);
      }

      //if landxml or ifc có tọa độ địa lý
      if ((item.data && item.type === 'landxml') || (item.crs.realWorldCoordinates && item.crs.realWorldCoordinates === 1) || isCloudPointXDEnginePlane(item) || item.type === 'e57') {
        xyzLocal1 = invXYZDialog(
          refLocalProject,
          Cartesian3.fromArray(localOrg),
          Cartesian3.fromArray(item.data.saveMatrix.xyzInput ? item.data.saveMatrix.xyzInput : [0, 0, 0])
        );

        const xyzDialog2 = getXYZDialog(
          refLocalProject,
          Cartesian3.fromArray(localOrg),
          xyzLocal1
        );
        posCurrent1.x = localOrg[0] + xyzDialog2.x;
        posCurrent1.y = localOrg[1] + xyzDialog2.y;
        posCurrent1.z = localOrg[2] + xyzDialog2.z;
      } else { // for mesh mode, ifc không có tọa độ địa lý
        posCurrent1.x = xyzLocal1.x;
        posCurrent1.y = xyzLocal1.y;
        posCurrent1.z = xyzLocal1.z;
      }
      let cartesian3ModelCenter = Cartesian3.fromArray(modelcenter);
      let shouldMerge = (item.type === 'landxml') || (item.crs.realWorldCoordinates === 1) || isCloudPointXDEnginePlane(item) || item.type === 'e57';
      let _mergeEditModelOnPlane = mergeEditModelOnPlane(
        refProject,
        refLocalProject,
        posCurrent1,
        cartesian3ModelCenter,
        item.data.saveMatrix.hpr.h,
        item.data.saveMatrix.hpr.p,
        item.data.saveMatrix.hpr.r,
        item.data.saveMatrix.scale,
        shouldMerge,
        headingRotation
      )
      let _data = item.data;
      let xyzLocal2 = new Cartesian3();
      //if landxml or ifc có tọa độ địa lý
      if ((item.type === 'landxml') || (item.crs.realWorldCoordinates && item.crs.realWorldCoordinates === 1) || isCloudPointXDEnginePlane(item) || item.type === 'e57') {
        // xyzLocal save value after invXYZDialog
        xyzLocal2.x = _mergeEditModelOnPlane.xyzLocal.x;
        xyzLocal2.y = _mergeEditModelOnPlane.xyzLocal.y;
        xyzLocal2.z = _mergeEditModelOnPlane.xyzLocal.z;
        _data.saveMatrix.fileOrigo = _mergeEditModelOnPlane.fileOrigo;
        if(isCloudPointXDEnginePlane(item) || item.type === 'e57'){
          const xyzLocalDialog = getXYZDialog(
            refLocalProject,
            Cartesian3.fromArray(localOrg),
            xyzLocal2
          );
          _data.saveMatrix.xyzLocal = [xyzLocalDialog.x, xyzLocalDialog.y, xyzLocalDialog.z];
        }else{
          _data.saveMatrix.xyzLocal = [xyzLocal2.x, xyzLocal2.y, xyzLocal2.z];
        }
      } else { // for mesh mode, ifc không có tọa độ địa lý
        xyzLocal2.x = _mergeEditModelOnPlane.xyzLocal.x + refLocalProject.x;
        xyzLocal2.y = _mergeEditModelOnPlane.xyzLocal.y + refLocalProject.y;
        xyzLocal2.z = _mergeEditModelOnPlane.xyzLocal.z + refLocalProject.z;

        if (georeferenced) {
          _data.saveMatrix.fileOrigo = _mergeEditModelOnPlane.fileOrigo;
          _data.saveMatrix.xyzLocal = [xyzLocal2.x, xyzLocal2.y, xyzLocal2.z];
        } else {
          if (!['kmz', 'geojson', 'geotiff'].includes(item.type)) {
            try {
              const response = await projectStore.convertProjectPlaneToWGS84(xyzLocal2.y, xyzLocal2.x, xyzLocal2.z, epsg, elevation);
              if (response.Status === "OK" && response.Point) {
                let _fileorigo = Cartesian3.fromDegrees(response.Point[1], response.Point[0], response.Point[2]);
                _data.saveMatrix.fileOrigo = _fileorigo;
                _data.saveMatrix.xyzLocal = [xyzLocal2.x, xyzLocal2.y, xyzLocal2.z];
              } else {
                throw new Error(`mergeAllModel ${item.name} is placed outside of project coordinate system. Please put the model into the project area.`);
              }
            } catch (error) {
              console.error("mergeAllModels convertProjectPlaneToWGS84: " + error);
            }
          }
        }
      }
      toUpdate.push({ _id: item._id, data: _data });
    } else {
      return item;
    }
  }));

  if (toUpdate.length > 0) {
    let rsp = await ProjectRequest.updateModelsMatrix(toUpdate);
    if (rsp.status === 200) {
      const result = rsp.data || [];
      let newModels = modelList.filter(c => c.newUpdate);
      if (newModels.length > 0) {
        newModels.map(c => {
          let isExist = result.find(m => m._id === c._id)
          if (!isExist) {
            result.push(c)
          }
        })
      }
      return result
    }
    return modelList
  }

  return _resultMergeAllModel
}


/**
 * Update project treeData, tilesetdata, model data
 * @param {*} _project 
 * @param {*} _modellist 
 * @param {*} _currentmodel 
 * @param {*} selectedKey // key tree menu selected
 * @param {*} projectStore 
 * @returns current model
 */
export const updateProjectModel = async (project, _currentmodel) => {
  // get latest project
  let resp = await projectStore.getProjectById(project._id);
  let _project = resp.data;
  let _modellist = resp.data.model3DS;

  let paramUpdateProject = {};
  //#region update project tilesetdata   
  if (!_project.tilesetData.RefPoint && _currentmodel.crs.RefPoint) { // if project not refpoint
    // check if model is xml or ifc coordinate then update refpoint, refLocalProject, georeferenced for project
    if ((_currentmodel.type === 'landxml') || (_currentmodel.crs.realWorldCoordinates && _currentmodel.crs.realWorldCoordinates === 1)) {
      paramUpdateProject.refLocalProject = _currentmodel.crs.LocalOrg;
      paramUpdateProject.refPoint = _currentmodel.crs.RefPoint;
      paramUpdateProject.georeferenced = true;
      let _epsg = _currentmodel.crs.epsgCode.slice(_currentmodel.crs.epsgCode.lastIndexOf(':') + 1).trim()
      // if project setting 4326 or project setting !== crs.epsg (ex: project setting 3880 import landxml 3879 => update project settings again follow xml)
      if (((['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'degree') || _project.tilesetData.coordinateSystem.code !== _epsg) && _epsg) {
        paramUpdateProject.coordinateSystemCode = _epsg;
      }
    } else {
      paramUpdateProject.refPoint = _currentmodel.crs.RefPoint;
    }
  } else {
    // for case upload ifc or mesh model first project only refpoint
    // if _currentmodel is landxml or ifc has cooordinate and project has RefPoint and georeferenced = false then update again project
    if ((_currentmodel.type === 'landxml' || (_currentmodel.crs.realWorldCoordinates && _currentmodel.crs.realWorldCoordinates === 1)) && !_project.tilesetData.georeferenced) {
      paramUpdateProject.refLocalProject = _currentmodel.crs.LocalOrg;
      paramUpdateProject.refPoint = _currentmodel.crs.RefPoint;
      paramUpdateProject.georeferenced = true;
      let _epsg = _currentmodel.crs.epsgCode.slice(_currentmodel.crs.epsgCode.lastIndexOf(':') + 1).trim()
      if ((['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'degree') && _epsg) {
        paramUpdateProject.coordinateSystemCode = _epsg;
      }
    } else {
      // for case if project has ifc coordinate then upload landxml then update project flow landxml
      let countlandxml = _modellist.reduce(function (r, a) {
        return r + +(a.type === 'landxml' && a._id.toString() !== _currentmodel._id.toString() && !a.isDeleted);
      }, 0);
      if (countlandxml === 0 && (_currentmodel.type === 'landxml') && _project.tilesetData.georeferenced) {
        paramUpdateProject.refLocalProject = _currentmodel.crs.LocalOrg;
        paramUpdateProject.refPoint = _currentmodel.crs.RefPoint;
        paramUpdateProject.georeferenced = true;
        let _epsg = _currentmodel.crs.epsgCode.slice(_currentmodel.crs.epsgCode.lastIndexOf(':') + 1).trim()
        if ((['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'degree') && _epsg) {
          paramUpdateProject.coordinateSystemCode = _epsg;
        }
      }
    }
  }

  // check if _currentmodel has ModelBox and filter all model in project has ModelBox > 0 and landxml or ifc coordinates
  // then we need to update project reference point calculation so that reference point is always updated into middle of project model data
  let modelBoxList = _modellist.filter(a => a.crs.ModelBox && (a.type === 'landxml' || (a.crs.realWorldCoordinates && a.crs.realWorldCoordinates === 1)) && a._id.toString() !== _currentmodel._id.toString() && !a.isDeleted);
  if (modelBoxList.length > 0 && _currentmodel.crs.ModelBox && (_currentmodel.type === 'landxml' || (_currentmodel.crs.realWorldCoordinates && _currentmodel.crs.realWorldCoordinates === 1))) {
    const previousBB = modelBoxList.reduce(function (previousValue, currentValue) {
      let modelBox_min = new Cartesian3(currentValue.crs.ModelBox[0], currentValue.crs.ModelBox[1], currentValue.crs.ModelBox[2]);
      modelBox_min.x += currentValue.crs.LocalOrg[0];
      modelBox_min.y += currentValue.crs.LocalOrg[1];
      modelBox_min.z += currentValue.crs.LocalOrg[2];

      let modelBox_max = new Cartesian3(currentValue.crs.ModelBox[3], currentValue.crs.ModelBox[4], currentValue.crs.ModelBox[5]);
      modelBox_max.x += currentValue.crs.LocalOrg[0];
      modelBox_max.y += currentValue.crs.LocalOrg[1];
      modelBox_max.z += currentValue.crs.LocalOrg[2];

      if (previousValue) {
        const midBB = expandBoundingBox(previousValue.minBB, previousValue.maxBB, modelBox_min, modelBox_max);
        return { minBB: midBB.minBB, maxBB: midBB.maxBB };
      }

      return { minBB: modelBox_min, maxBB: modelBox_max };
    }, null);

    let modelBox1_min = new Cartesian3(_currentmodel.crs.ModelBox[0], _currentmodel.crs.ModelBox[1], _currentmodel.crs.ModelBox[2]);
    modelBox1_min.x += _currentmodel.crs.LocalOrg[0];
    modelBox1_min.y += _currentmodel.crs.LocalOrg[1];
    modelBox1_min.z += _currentmodel.crs.LocalOrg[2];
    let modelBox1_max = new Cartesian3(_currentmodel.crs.ModelBox[3], _currentmodel.crs.ModelBox[4], _currentmodel.crs.ModelBox[5]);
    modelBox1_max.x += _currentmodel.crs.LocalOrg[0];
    modelBox1_max.y += _currentmodel.crs.LocalOrg[1];
    modelBox1_max.z += _currentmodel.crs.LocalOrg[2];

    //mid bounding box
    const midBBObj = expandBoundingBox(previousBB.minBB, previousBB.maxBB, modelBox1_min, modelBox1_max);
    paramUpdateProject.refLocalProject = [midBBObj.centerBB.x, midBBObj.centerBB.y, midBBObj.centerBB.z];

    //update Refpoint
    let _convertPLToWGS84 = await projectStore.convertProjectPlaneToWGS84(midBBObj.centerBB.y, midBBObj.centerBB.x, midBBObj.centerBB.z, _project.tilesetData.coordinateSystem.code, (_project.elevationSystem ? _project.elevationSystem : 'None'))
    if (_convertPLToWGS84.Status === 'OK') {
      paramUpdateProject.refPoint = _convertPLToWGS84.Point;
    }
  } else {
    // if project has epsg !==4326 and project does not have landxml, ifc coordinate, model is mesh, ifc not have coordinate then need calculate project refpoint convertProjectPlaneToWGS84
    // with ifc ko có tọa độ địa lý, hoặc mesh model khi kéo model vào thì điểm kéo vào đó được set cho project RefPoint
    // Khi người dùng input xyz vào sẽ kiểm tra nếu project không có refLocalProject thì sẽ lấy data input xyz vào của user
    // làm tọa độ thưc của model
    // vi Project không có  georeferenced nen IFC, mesh model xu ly nhu la Mesh o 4326
    if ((!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre') && modelBoxList.length === 0 && !_project.tilesetData.RefPoint && _currentmodel.crs.RefPoint && ((isIFCPlane(_currentmodel) && _currentmodel.crs?.realWorldCoordinates === 0) || _currentmodel.data.ext === '.gltf' || _currentmodel.data.ext === '.glb' || _currentmodel.data.ext === '.obj' || _currentmodel.data.ext === '.fbx' || _currentmodel.data.ext === '.dae' || _currentmodel.data.ext === '.zip' || _currentmodel.data.ext === '.cad' || isCloudPointXDEnginePlane(_currentmodel) || isCloudPointCesiumION(_currentmodel) || isE57XDEnginePlane(_currentmodel))) {
      let _convertPLToWGS84 = await projectStore.convertProjectPlaneToWGS84(_currentmodel.crs.RefPoint[0], _currentmodel.crs.RefPoint[1], _currentmodel.crs.RefPoint[2], 4326, (_project.elevationSystem ? _project.elevationSystem : 'None'))
      if (_convertPLToWGS84.Status === 'OK') {
        paramUpdateProject.refPoint = _convertPLToWGS84.Point;
      }
    }
  }

  let resultUpdatepProject = _project;
  if (_.keys(paramUpdateProject).length > 0) { //if project change
    resultUpdatepProject = await projectStore.updateProjectTilesetData(_project._id, paramUpdateProject);
  }
  //#endregion

  // if project has epsg = 4326 và có 1 số model ifc, mesh model.. then upload landxml or ifc có tạo độ địa lý thì
  // phải tính toán lại xyzlocal, LocalOrg của các model đã upload
  if (((['4326', '4756'].includes(resultUpdatepProject.tilesetData.coordinateSystem.code) || resultUpdatepProject.tilesetData.coordinateSystem.unit === 'degree')) && _currentmodel.crs.RefPoint && (_currentmodel.type === 'landxml' || (_currentmodel.crs.realWorldCoordinates && _currentmodel.crs.realWorldCoordinates === 1))) {
    let _listmodel3d = resultUpdatepProject.model3DS.filter((el) => {
      return el.type !== 'landxml' && (!el.crs.realWorldCoordinates || el.crs.realWorldCoordinates === 0) && !el.isDeleted
    });
    if (_listmodel3d.length > 0 && resultUpdatepProject.tilesetData?.RefPoint) {
      await Promise.all(_listmodel3d.map(async (item) => {
        if (item.data?.saveMatrix?.xyzLocal) {
          let xyzLocal = Cartesian3.fromDegrees(item.data.saveMatrix.xyzLocal[1], item.data.saveMatrix.xyzLocal[0], item.data.saveMatrix.xyzLocal[2])
          let _RefPoint = Cartesian3.fromDegrees(resultUpdatepProject.tilesetData.RefPoint[1], resultUpdatepProject.tilesetData.RefPoint[0], resultUpdatepProject.tilesetData.RefPoint[2])
          let _refLocalProject = Cartesian3.fromArray(resultUpdatepProject.tilesetData.refLocalProject);

          const xyzLocalUpdate = cnvECEFtoENU(_RefPoint, xyzLocal);
          item.data.saveMatrix.xyzLocal = [xyzLocalUpdate.x + _refLocalProject.x, xyzLocalUpdate.y + _refLocalProject.y, xyzLocalUpdate.z + _refLocalProject.z]
          item.crs.LocalOrg = item.data.saveMatrix.xyzLocal;

          await projectStore.update3DMODELS(item._id, item).then(res => {
            let objIndex = resultUpdatepProject.model3DS.findIndex((obj => obj._id.toString() == _currentmodel._id.toString()));
            if (objIndex > -1) {
              resultUpdatepProject.model3DS[objIndex] = res.data
            }
          }).catch(error => {
            console.log(error)
          })
        }
      }));
    }
  }

  // if _currentmodel is ifc no coodinates
  if ((_currentmodel.type === 'ifc' || _currentmodel.type === 'cad') && _currentmodel.crs.realWorldCoordinates === 0 && _currentmodel.data?.ifcSetting?.importer !== 'ellipsoid') {
    let _refProject = Cartesian3.fromDegrees(_currentmodel.crs.RefPoint[1], _currentmodel.crs.RefPoint[0], _currentmodel.crs.RefPoint[2])
    if (resultUpdatepProject.tilesetData?.RefPoint) {
      _refProject = Cartesian3.fromDegrees(resultUpdatepProject.tilesetData.RefPoint[1], resultUpdatepProject.tilesetData.RefPoint[0], resultUpdatepProject.tilesetData.RefPoint[2])
    }
    let _refLocalProject = Cartesian3.fromArray(_currentmodel.crs.LocalOrg)
    if (!resultUpdatepProject.tilesetData.georeferenced) {
      let _convertPLToWGS84 = await projectStore.convertProjectPlaneToWGS84XYZ(_currentmodel.crs.RefPoint[0], _currentmodel.crs.RefPoint[1], _currentmodel.crs.RefPoint[2], resultUpdatepProject.tilesetData.coordinateSystem.code, (_project.elevationSystem ? _project.elevationSystem : 'None'))
      if (_convertPLToWGS84.Status === 'OK') {
        _refLocalProject = Cartesian3.fromArray([_convertPLToWGS84.LocalPosition[1], _convertPLToWGS84.LocalPosition[0], _convertPLToWGS84.LocalPosition[2]]);
      }
    }

    if ((!['4326', '4756'].includes(resultUpdatepProject.tilesetData.coordinateSystem.code) || resultUpdatepProject.tilesetData.coordinateSystem.unit === 'metre')) {
      //Calc xyzlocal, fileOrigo
      const insertPointIFC = Cartesian3.fromDegrees(_currentmodel.crs.RefPoint[1], _currentmodel.crs.RefPoint[0], _currentmodel.crs.RefPoint[2])
      let localOrg2 = cnvECEFtoENU(_refProject, insertPointIFC);
      localOrg2.x += _refLocalProject.x;
      localOrg2.y += _refLocalProject.y;
      localOrg2.z += _refLocalProject.z;

      if (JSON.stringify(_currentmodel.data.saveMatrix.xyzLocal) === JSON.stringify([0, 0, 0])) {
        _currentmodel.data.saveMatrix.xyzLocal = [localOrg2.x, localOrg2.y, localOrg2.z];
      }

      let _convertPLToWGS84 = await projectStore.convertProjectPlaneToWGS84(_currentmodel.data.saveMatrix.xyzLocal[1], _currentmodel.data.saveMatrix.xyzLocal[0], _currentmodel.data.saveMatrix.xyzLocal[2], resultUpdatepProject.tilesetData.coordinateSystem.code, (_project.elevationSystem ? _project.elevationSystem : 'None'));
      if (_convertPLToWGS84.Status === 'OK' && _convertPLToWGS84.Point) {
        _currentmodel.data.saveMatrix.fileOrigo = Cartesian3.fromDegrees(_convertPLToWGS84.Point[1], _convertPLToWGS84.Point[0], _convertPLToWGS84.Point[2]);
      }
      _currentmodel.crs.LocalOrg = [_refLocalProject.x, _refLocalProject.y, _refLocalProject.z];
    } else {
      _currentmodel.data.saveMatrix.xyzLocal = [
        _currentmodel.crs.initPos.cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN,
        _currentmodel.crs.initPos.cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN,
        _currentmodel.crs.initPos.cartographic.height
      ]
      _currentmodel.data.saveMatrix.fileOrigo = Cartesian3.fromDegrees(_currentmodel.crs.initPos.cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _currentmodel.crs.initPos.cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _currentmodel.crs.initPos.cartographic.height)

      // if ifc, mesh model Model Center > 100000 then
      if (_currentmodel.crs.ModelCenter) {
        const modelCenter2 = new Cartesian3(_currentmodel.crs.ModelCenter[0], _currentmodel.crs.ModelCenter[1], _currentmodel.crs.ModelCenter[2]);
        const dist = Cartesian3.distance(new Cartesian3(0.0, 0.0, 0.0), modelCenter2);
        if (dist > 100000) {
          _currentmodel.crs.ModelCenter = [0, 0, 0];
        }
      }
    }
    await projectStore.update3DMODELS(_currentmodel._id, _currentmodel).then(res => {
      let objIndex = resultUpdatepProject.model3DS.findIndex((obj => obj._id.toString() == _currentmodel._id.toString()));
      if (objIndex > -1) {
        resultUpdatepProject.model3DS[objIndex] = res.data
      }
    }).catch(error => {
      console.log(error)
    })
  }

  _currentmodel.project = resultUpdatepProject; // reassign project to model
  return _currentmodel;
}

/**
 * Create IFC Engine Usage
 * @param {*} orgid 
 * @param {*} projectId 
 * @param {*} model 
 * @param {*} userId 
 */
export const create_ifcengineusage = async (orgid, projectId, model, userId) => {
  try {
    const _totalLicenses = await licenseStore.getOrgLicenses(orgid)
    let _licenseType = _totalLicenses.filter(elm => elm.licensetype);
    let _typeName = [];
    _licenseType.map(elm => {
      let isExist = _typeName.find(element => element === elm.licensetype._id);
      if (isExist) return;
      _typeName.push(elm.licensetype);
    });
    let ifcengineusageParam = {
      type: model.type === 'landxml' ? 'convert_xml' : 'convert_ifc',
      user: userId,
      modelId: model._id,
      Name: model.name,
      licensetypes: _typeName,
      organizationId: orgid,
      projectId: projectId
    };
    await licenseStore.createIfcEngineUsage(ifcengineusageParam);
  } catch (error) {
    console.log(error)
  }
}

/**
 * Update Project treeData (Separated because uploading multiple files in parallel would not be correct if added together in the project)
 * @param {*} projectId 
 * @param {*} modelList 
 * @param {*} treeData 
 * @returns treeData
 */
export const updateProjectTreeData = async (projectId, model, treeData = [], accesscontrols) => {
  const newNodes = [];
  let node = TreeUtils.searchTreeNode(treeData, 'modelId', model._id);
  let refId = model._id
  if (model.sourceType === 'WMS' || model.sourceType === 'WFS') {
    const { hostname } = new URL(model.src);
    refId = `${hostname}-${model.data?.layers}`
  }

  if (!node) {
    const newNode = {
      title: model.name,
      key: uuid(),
      type: 'FILE',
      modelId: model._id,
      sourceType: model.sourceType,
      modelType: model.type,
      useService: model.useService,
      refId: refId,
      hash: model.hash,
      ext: model.data.ext,
      src: model.src,
      isVisible4D: model.isVisible4D,
      endDate: model.endDate,
      startDate: model.startDate,
      isVisibleClip: model.isVisibleClip,
      isDeleted: false
    }
    if (accesscontrols) {
      newNode.accesscontrols = accesscontrols
    }
    treeData = addTreeNode(newNode, treeData, model.selectedKey);
    newNodes.push(newNode);
    node = TreeUtils.searchTreeNode(treeData, 'modelId', model._id);
  }
  if (node) {
    if (node.hash !== model.hash) {
      const nodeUpdate = {
        title: model.name,
        key: uuid(),
        type: 'FILE',
        modelId: model._id,
        sourceType: model.sourceType,
        modelType: model.type,
        useService: model.useService,
        refId: refId,
        hash: model.hash,
        ext: model.data.ext,
        src: model.src,
        isVisible4D: model.isVisible4D,
        endDate: model.endDate,
        startDate: model.startDate,
        isVisibleClip: model.isVisibleClip,
        isDeleted: false
      }
      if (accesscontrols) {
        nodeUpdate.accesscontrols = accesscontrols
      }
      treeData = TreeUtils.updateTreeDataByModelId(treeData, node.modelId, nodeUpdate)
      newNodes.push(nodeUpdate);
      node = TreeUtils.searchTreeNode(treeData, 'modelId', model._id);
    } else {
      node.sourceType = model.sourceType
      node.refId = refId
      node.modelType = model.type
      node.ext = model.data.ext
      node.isVisible4D = model.isVisible4D
      node.endDate = model.endDate
      node.startDate = model.startDate
      node.isVisibleClip = model.isVisibleClip
    }
  }

  if (newNodes.length) {
    let rsp = await ProjectRequest.updateProject(projectId, { treeData: treeData, store: 'treeData' });
    if (rsp.status === 200) {
      return rsp.data.treeData
    }
  }

  return treeData
}

export const checkAvaiableLicenses = (licenses = []) => {
  const response = []
  if (licenses.length > 0) {
    for (let i = 0; i < licenses.length; i++) {
      let startLicenseDate = licenses[i].activated ? licenses[i].activated : licenses[i].createdAt
      let endLicenseDate = licenses[i].expiration ? licenses[i].expiration : moment(startLicenseDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').add(licenses[i]?.timeLimit || 0, 'days')
      if (!(licenses[i].isActive) || !(moment(Date.now()).isBetween(startLicenseDate, endLicenseDate)) || (licenses[i].isActive && (moment() < moment(startLicenseDate)))) continue
      response.push(toJS(licenses[i]))
    }
  }
  return response
}

// update organization quota usage
export const updateOrganizationQuotaUsage = async (projectId, _currentmodel, listproject = [], licenses, currentOrganizations) => {
  let avaiableLicenses = checkAvaiableLicenses(licenses)
  let sumSizes = parseInt(_currentmodel?.data?.size ?? 0)
  let storageQuotaUsage = 0;
  let totalQuota = 0
  if (listproject.length > 0) {
    for (let i = 0; i < listproject.length; i++) {
      let project = listproject[i]
      if (project?.isDeleted) continue
      if (project.storage) {
        storageQuotaUsage = parseInt(storageQuotaUsage) + parseInt(project.storage * 1024)
      }
    }
  }
  if (avaiableLicenses.length > 0) {
    for (let j = 0; j < avaiableLicenses.length; j++) {
      let license = avaiableLicenses[j]
      if (license?.isDeleted) continue
      totalQuota += license.storageQuota || 0
    }
  }
  const quota = {
    storageQuotaUsage: parseInt(sumSizes) + parseInt(storageQuotaUsage),
    percentQuotaUsage: ((parseInt(sumSizes) + parseInt(storageQuotaUsage)) / (totalQuota * 1024 * 1024 * 1024)) * 100,
    quotaUsageLeft: totalQuota - ((parseInt(sumSizes) + parseInt(storageQuotaUsage)) / (1024 * 1024 * 1024))
  }
  await projectStore.updateProjectStorage(projectId)
  await organizationStore.updateOrganizationQuota(currentOrganizations, quota.storageQuotaUsage, quota.percentQuotaUsage, quota.quotaUsageLeft).then(async res => {
    if (res && res.warning) {
      await organizationStore.sendWarningQuotaLicense({ organization: currentOrganizations, percentQuotaUsage: res.threshold })
    }
  })
}

export const processPublicLink = async (model) => {
  let linkjson = await Utils.getTileSetUrlFromModel(model)
  let headers = {
    'Content-type': 'application/json',
  }
  if (linkjson.accessToken) {
    headers.Authorization = `Bearer ${linkjson.accessToken}`
  }
  await axios(linkjson.tileSetUrl, {
    method: 'GET',
    headers
  })
    .then((res) => {
      return res.data
    })
    .then(async (data) => {
      // Update json transform if follow model project
      if (data?.root?.transform) {
        if(model.data.transform) data.root.transform = model.data.transform

        let jsontext = JSON.stringify(data);
        let modelName = 'external_tileset.json'
        let key = `${model.hash}/${modelName}`
        const presignedS3Url = await projectStore.generatePreSignedPutUrl({ fileName: modelName, projectId: projectStore.projectDetail._id, undefined, readkey: key })
        const config = {
          method: 'put',
          url: presignedS3Url.url,
          headers: {
            'Content-Type': getMimeOfFile(modelName)
          },
          data: pBuffer.from(jsontext, 'utf8'),
        };
        let res = await axios(config)
        if (res.status === 200) {
        } else {
          console.log('error processPublicLink')
        }
      }
    }).catch((e) => console.log(e))
}

export const getVisible4DIconModel = (projectStore, sketchingStore) => {
  let _modelHiden = projectStore.visibleTilesets.filter(c => !c.isVisible4D)
    .map(elm => {
      if (!elm.isVisible4D) {
        return {
          modelId: elm.modelId, type4D: 'model', isVisible4D: false
        }
      }
    })

  let _visibleSketches = (Array.isArray(sketchingStore.visibleSketches) ? sketchingStore.visibleSketches.filter(c => !c.isVisible4D) : [])
    .map(elm => {
      if (!elm.isVisible4D) {
        return {
          sketchId: elm.sketchId, type4D: 'sketch', isVisible4D: false
        }
      }
    })
  return [..._modelHiden, ..._visibleSketches]
}

export const getVisileDataTree = (projectStore, topicStore, sketchingStore, feedbackStore, userId) => {
  let clippingMode = projectStore.clippingMode
  let clippingViewPoint = projectStore.clippingViewPoint
  // save visible tree
  let projectLinks = userId ? projectStore.listProjectLink?.map(x => {
    let data = {
      visibleData: [{
        userId,
        isVisibleClip: x.isVisibleClip || false,
        isVisible: x.isVisible || false,
        isVisible4D: x.isVisible4D || false
      }],
      projectLinkId: x.id,
      title: x.title
    }
    return data
  }) : []
  let _visible4DFolders = Array.isArray(projectStore.visible4DFolders) ? projectStore.visible4DFolders : []
  let _visibleTilesets = Array.isArray(projectStore.visibleTilesets) ? projectStore.visibleTilesets.map(c => {
    return { modelId: c?.modelId, isTempHidden: c.isTempHidden, isVisible: c?.isVisible }
  }) : []

  let _visibleSketches = Array.isArray(sketchingStore.visibleSketches) ? sketchingStore.visibleSketches.map(c => {
    return { sketchId: c?.sketchId, isTempHidden: c.isTempHidden, isVisible: c?.isVisible }
  }) : []

  let _visibleClipModels = Array.isArray(projectStore.visibleTilesets) ? projectStore.visibleTilesets.map(c => {
    return { modelId: c?.modelId, isVisibleClip: c?.isVisibleClip }
  }) : []

  let _visibleTopic = Array.isArray(topicStore.visibleTopic) ? topicStore.visibleTopic.map(c => {
    return { controlName: c?.controlName, isShow: c?.isShow }
  }) : []

  let _visibleFeedback = Array.isArray(feedbackStore.visibleFeedback) ? feedbackStore.visibleFeedback.map(c => {
    return { controlName: c?.controlName, isShow: c?.isShow }
  }) : []

  let _visibleFeedbackForms = Array.isArray(feedbackStore.visibleFeedbackForm) ? feedbackStore.visibleFeedbackForm.map(c => {
    return { controlName: c?.controlName, isShow: c?.isShow }
  }) : []

  let data = {
    visibleTopic: _visibleTopic.filter(c => !c.isShow),
    visibleTilesets: _visibleTilesets.filter(c => !c.isVisible),
    visibleSketches: _visibleSketches.filter(c => !c.isVisible),
    visibleFeedback: _visibleFeedback.filter(c => !c.isShow),
    visibleFeedbackForms: _visibleFeedbackForms.filter(c => !c.isShow),
    visible4DModels: getVisible4DIconModel(projectStore, sketchingStore),
    visibleClipModels: _visibleClipModels.filter(c => !c.isVisibleClip),
    projectLinks,
    visible4DFolders: _visible4DFolders
  }
  // save clipping to viewpoint
  if (clippingMode && clippingViewPoint) {
    data.clippingData = {}
    data.clippingData.clippingMode = projectStore.clippingMode
    data.clippingData.position = projectStore.clippingViewPoint.position
    data.clippingData.direction = projectStore.clippingViewPoint.direction
    data.clippingData.dimension = projectStore.clippingViewPoint.dimension
    data.clippingData.distance = projectStore.clippingViewPoint?.distance
    data.clippingData.listStation = projectStore.clippingViewPoint?.listStation
    data.clippingData.currentStep = projectStore.clippingViewPoint.currentStep
  }

  if (projectStore.projectDetail.tilesetData?.hiddenArea) {
    data.hideAreaData = projectStore.projectDetail.tilesetData?.hiddenArea
  }

  if (projectStore.navigationStyles?.distanceLimit) {
    data.navigationDistanceLimit = projectStore.navigationStyles?.distanceLimit || -1;
  }

  data.navigationAllowUnderground = projectStore.navigationStyles?.allowUnderground ?? true;
  return data;
}

export const addViewPointCameraData = (projectStore, commonStore, cam, metadata, viewer) => {
  let maximumAttenuation = (metadata?.renderResolution?.maximumAttenuation || projectStore.maximumAttenuation) || 5
  let geometricErrorScale = (metadata?.renderResolution?.geometricErrorScale || projectStore.geometricErrorScale) || 1
  let eyeDomeLightingStrength = (metadata?.renderResolution?.eyeDomeLightingStrength || projectStore.eyeDomeLightingStrength) || 1
  let eyeDomeLightingRadius = (metadata?.renderResolution?.eyeDomeLightingRadius || projectStore.eyeDomeLightingRadius) || 1
  let shadowDarkness = (metadata?.renderResolution?.shadowDarkness || projectStore.shadowDarkness) || 0.5
  let shadowDistance = (metadata?.renderResolution?.shadowDistance || projectStore.shadowDistance) || 1000
  let shadowAccuracy = (metadata?.renderResolution?.shadowAccuracy || projectStore.shadowAccuracy) || 4096
  let softShadows = (metadata?.renderResolution?.softShadows || projectStore.softShadows) || false
  cam.cameraData.renderResolution = {
    shadowDarkness,
    shadowDistance,
    shadowAccuracy,
    softShadows,
    maximumAttenuation,
    eyeDomeLightingStrength,
    eyeDomeLightingRadius,
    geometricErrorScale
  }
  cam.cameraData.lightSetting = metadata.lightSetting
  cam.cameraData.selectedBaselayer = viewer.baseLayerPicker.viewModel.selectedImagery.name
  cam.cameraData.is2D = projectStore.is2D
  cam.cameraData.showMap = projectStore.showMap
  cam.cameraData.enableShadows = commonStore.enableShadows
  cam.cameraData.globeBehind = projectStore.globeBehind
  cam.cameraData.nearValue = commonStore.nearValue
  cam.cameraData.farDistance = commonStore.farDistance
  cam.cameraData.nearDistance = commonStore.nearDistance
  return cam;
}

export const generateUsername = (item) => {
  return item?.firstName && item?.lastName ?
    item.firstName + ' ' + item.lastName : !item?.firstName && item?.lastName ?
      item.lastName : item?.firstName && !item?.lastName ?
        item.firstName : item.username
}

export const getFeedbackIcon = (dbIcon, defaultIcon) => {
  const regex = /\/static\/media\/(smiley\d+)\./;
  const icon = dbIcon.map((el) => {
    const match = el.match(regex);
    if (match) {
      const l = match[1]; // "smiley1"
      const matchingDIcon = defaultIcon.find(di => di.includes(l));
      if (matchingDIcon) {
        return matchingDIcon;
      }
    }
    return '';
  });

  return icon;
}
export const waitUntilIonReadyData = async (assetId, modelId) => {
  return new Promise((resolve) => {
    if (!assetId) resolve(false);
    const waitUntilReady = async () => {
      await axios({
        url: `https://api.cesium.com/v1/assets/${assetId}`,
        headers: { Authorization: `Bearer ${cesiumToken}` },
        json: true
      }).then(async r => {
        let status = r.data.status;
        if (status === 'COMPLETE') {
          const params = {
            assetId,
            modelId,
            typeIon: r.data.type
          }
          await projectStore.updateModelStatus(modelId, { ionProgressing: 100, ionStatus: status, progressStatus: status })
          let check = await projectStore.downloadIonUploadToS3(params)
          if (check) {
            resolve(true)
          } else {
            resolve(false);
          }
        } else if (status === 'DATA_ERROR' || status === 'ERROR') {
          resolve(false);
        } else {
          setTimeout(waitUntilReady, 5000);
        }
      }).catch(err => {
        resolve(false);
      });
    };
    (async () => {
      await waitUntilReady();
    })();
  });
}
export const updateContenModelIon = async (model, file) => {
  const assetId = model.data.ionAssetId;
  const modelId = model._id || model.id;
  return new Promise((resolve) => {
    axios({
      url: `https://api.cesium.com/v1/assets/${assetId}`,
      headers: { Authorization: `Bearer ${cesiumToken}` },
      json: true
    }).then(async r => {
      let status = r.data.status;
      if (status === 'COMPLETE') {
        const params = {
          assetId,
          modelId,
          typeIon: r.data.type
        }
        await projectStore.updateModelStatus(modelId, { ionProgressing: 100, ionStatus: status, progressStatus: status })
        let check = await projectStore.downloadIonUploadToS3(params)
        if (check) {
          resolve(true)
        } else {
          resolve(false);
        }
      } else if (model.data?.uploadLocation && model.data.ionStatus === 'AWAITING_FILES') {
        const uploadLocation = model.data.uploadLocation;
        const s3 = new AWS.S3({
          apiVersion: '2006-03-01',
          region: 'us-east-1',
          signatureVersion: 'v4',
          endpoint: uploadLocation.endpoint,
          credentials: new AWS.Credentials(
            uploadLocation.accessKey,
            uploadLocation.secretAccessKey,
            uploadLocation.sessionToken)
        });
        await s3.upload({
          Body: file,
          Bucket: uploadLocation.bucket,
          Key: `${uploadLocation.prefix}${file.name ? file.name : 'Unnamed'}${file.ext}`
        }).promise();
        await axios({
          url: `https://api.cesium.com/v1/assets/${assetId}/uploadComplete`,
          method: 'POST',
          headers: { Authorization: `Bearer ${cesiumToken}` },
          json: true,
          body: {}
        });
        let checkCreate = await waitUntilIonReadyData(assetId, modelId);
        resolve(checkCreate)
      } else if (['IN_PROGRESS', 'NOT_STARTED'].includes(model.data.ionStatus)) {
        let checkCreate = await waitUntilIonReadyData(assetId, modelId);
        resolve(checkCreate)
      } else resolve(true)
    }).catch(err => {
      resolve(false);
    });
  })
}

export const convertExtToLowerCase = (filename) => {
  const parts = filename.split('.');
  if (parts.length > 1) {
    const ext = parts.pop().toLowerCase();
    return `${parts.join('.')}.${ext}`;
  }
  return filename;
}

/**
 * Update data tree when add new model ellipsoid, add to modelList
 * @param {*} treeData 
 * @param {*} model3DS 
 * @returns new treeData, new modelList
 */

export const addNewEllipsoidModel = (treeData, modelList, currentModel) => {
  projectStore.setProjectDetail({
    ...projectStore.projectDetail,
    treeData
  })

  const newModelList = []
  modelList.map(c => newModelList.push(c))
  let isExist = newModelList.findIndex(c => c._id === currentModel._id)
  if (isExist !== -1) {
    newModelList[isExist] = currentModel
  } else {
    newModelList.push(currentModel)
  }

  projectStore.setModelList(newModelList)
}


export const checkChangeWMSOrder = (treeData, ) => {
  function mapOrder(array, order, key) {
    const c = order.map((i) => array.find((j) => j.id === i.modelId));
    return toJS(c)
  }
  const curOrder = projectStore.WMSOrders.map(c => toJS(c));
  const listOuterWMS = TreeUtils.findAllNode(treeData) || [];
  const listAllModel3DSLink = TreeUtils.findAllProjectLinkNode(projectStore?.listAllModel3DSLink || []);
  const mergeOuterWMS = listOuterWMS.concat(listAllModel3DSLink)
  const modelList = projectStore.modelList.filter(model => (model.sourceType === 'WMS' || model.type === 'geotiff') && model.isDeleted === false)
  let ordered_array = mapOrder(modelList, mergeOuterWMS, 'id').filter(function (element) {
    return element !== undefined;
  });
  let reverseModelList = ordered_array.slice().reverse();
  const newOrder = reverseModelList.map(c => toJS(c));
  function areSameOrderAndElements(A, B) {
    if (A.length !== B.length) {
      return false;
    }

    for (let i = 0; i < A.length; i++) {
      if (A[i].id !== B[i].id) {
        return false; 
      }
    }

    // All elements in the same order and with the same IDs
    return true;
  }
  const isSame = areSameOrderAndElements(curOrder,newOrder);
  if(!isSame){
    projectStore.setRerenderWMS(!projectStore.rerenderWMS)
  }
}


export const  renderTermsPolicyHref = (data,type)  => {
  const siteName = siteMode ? siteMode : "xd-twin" ;
  const domainName = siteName === "xd-twin" ? (domain?.XD ? domain?.XD : "https://www.xd-twin.io/") : (
    domain["6D"] ? domain["6D"] :"https://www.6dplanner.com/"
  )
  if(type ==='policy'){
    return data?.policy ? (domainName + data?.policy ) : '#'
  }
  if(type ==='terms'){
    return data?.terms ? (domainName + data?.terms ) : '#'
  }
}

export const is6DPLANNER = (FRONTEND = 'https://test.xd-twin.io') => {
  const has6dplanner = FRONTEND.includes('6dplanner');
  const hasXDTwin = FRONTEND.includes('xd-twin');
  return has6dplanner;
}