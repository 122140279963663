import { ColumnWidthOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty, Form, Popconfirm, Row, Select, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { Fragment, useCallback, useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useParams, withRouter } from 'react-router-dom'
import HelpButton from '../../../elements/HelpButton'
import { ActionDHXTool, ActionToolDrawer, CustomSplitMobile } from './style.js'
import { toJS } from 'mobx'
import uuid, {  } from 'uuid';
import DHTMLXSpreadsheet from './Spreadsheet'
import CalculationEditor from './CalculationEditor'
import axios from 'axios'
import { apiUrl } from '../../../../config'
var cancelToken
const DrawerCalculation = ({ projectStore, dashboardStore, commonStore, reportStore, calculationStore }) => {
  const { t } = useTranslation();
  var widthDevice = window.innerWidth
  const isTabletOrMobileDevice = isMobile || isTablet
  const [drawerWidth, setDrawerWidth] = useState(((widthDevice * 10) / 10) || 500)
  const { projectId } = useParams();
  moment.locale(`${commonStore.language}`)
  const { sheetDatas, setSheetDatas, setCalculationsSettings, setCalculationEditor, calculationsSettings } = calculationStore;
  const [, setSheetLoadingPercent] = useState([]);
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const [folderList, setFolderList] = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [objectDatas, setObjectDatas] = useState({})
  //#region event for mouse and touche split panel
  const handleTouchStart = e => {
    e.preventDefault();
    if (e.touches.length !== 1) return null;
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
  };

  const handleTouchMove = useCallback(e => {
    let touch = e.touches[0] || e.changedTouches[0];
    let target = document.elementFromPoint(touch.clientX, touch.clientY);
    let newWidth = (document.body.offsetWidth ? document.body.offsetWidth : 0) - (touch.clientX - (target && target.offsetLeft ? target.offsetLeft : 0));
    let minDrawerWidth = 360;
    if (newWidth < 0) {
      setDrawerWidth(10);
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    document.removeEventListener("touchmove", handleTouchMove, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    const newWidth = document.body.scrollWidth - e.clientX; //  e.clientX + document.body.offsetLeft;
    const minDrawerWidth = 375;
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);

  const handleChangeScreenSize = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleChangeScreenSize)

  useEffect(() => {
    if (drawerWidth > screenWidth) {
      setDrawerWidth(screenWidth)
    }
  }, [screenWidth])

  useEffect(() => {
    if (calculationsSettings) {
      let u = calculationsSettings.map(c => {
        return {
          value: c.key,
          label: c.name,
        }
      })
      setOptions(u)
    }
  }, [calculationsSettings])

  useEffect(() => {
    if (projectStore.showDrawerCalculation) {
      const fetch = async () => {
        dashboardStore.setLoadingProgress(true);
        reportStore.setLoadingProgress(true)
        await projectStore.getProjectCustomAttributes(projectId);
        await reportStore.getCalculationSettings(projectId).then(async res => {
          let reportSetting = res.reportSetting ? res.reportSetting : []
          setCalculationsSettings(reportSetting)
          reportStore.setLoadingProgress(false)
        }).catch(err => {
          reportStore.setLoadingProgress(false)
        })
        dashboardStore.setLoadingProgress(false);
        if (projectStore.projectDetail?.treeData) {
          const treeData = projectStore.projectDetail?.treeData || []
          const result = [
            {
              key: "mainfolder",
              title: "All",
              isDeleted: false,
              parentKey: undefined,
              type: "FOLDER",
              children: []
            }
          ]
          const getFolderTreeData = tree => {
            const stack = []
            tree.map(item => stack.push(item))
            while (stack.length > 0) {
              const node = stack.shift()
              if (node.type === 'FOLDER') {
                const u = {
                  key: node.key,
                  parentKey: node.parentKey,
                  title: node.title,
                  type: node.type,
                  isDeleted: node.isDeleted,
                }
                result.push(u)
                if (node.children && node.children) {
                  getFolderTreeData(node.children)
                }
              }
            }
            return result
          }
          const data = getFolderTreeData(treeData)
          setFolderList(data)
        }
      }
      fetch()
    }
    return () => {
      setSheetDatas([])
      setObjectDatas({})
      calculationStore.setSelectedCalculation(false)
      calculationStore.setAdditionCells({})
    }
  }, [projectStore.showDrawerCalculation])


  const defaultConfig = {
    styles: {
      header: {
        "font-weight": "bold",
        "white-space": "nowrap",
      },
      headerRules: {
        "font-weight": "bold",
        "white-space": "nowrap",
        "word-break": 'break-all',
        "multiline": "wrap"
      },
      highlighting: {
        color: "#0b72a5",
        "white-space": "nowrap",
      },
      rulesButton: {
        "background": "#38a8d7",
        "color": 'white',
        "white-space": "wrap",
        "width": '200px'
      }
    },
    formats: [
      {
        name: "Common",
        id: "common",
        mask: "",
        example: "1500.31",
      },
      {
        name: "Number",
        id: "number",
        mask: "#,##0.00",
        example: "1500.31",
      },
      {
        name: "Percent",
        id: "percent",
        mask: "#,##0.00%",
        example: "15.0031",
      },
      {
        name: "Currency",
        id: "currency",
        mask: "$#,##0.00",
        example: "1500.31",
      },
      {
        name: "Date",
        id: "date",
        mask: "mm-dd-yy",
        example: "44490",
        dateFormat: "%d/%m/%Y",
      },
      {
        name: "Time",
        id: "time",
        mask: "h:mm:ss am/pm",
        example: "0.5625",
        timeFormat: 12,
      },
      {
        name: "Text",
        id: "text",
        mask: "@",
        example: "some text",
      },
    ],
  }

  const saveLastSetting = (data = calculationsSettings) => {
    if (projectStore.showDrawerCalculation && data) {
      const reportSettings = data.map(element => {
        return {
          columns: element.columns,
          key: element.key,
          level: element.level,
          name: element.name,
          rules: element.rules,
          isFetching: false,
          sheetdata: [],
          additionCells: element.additionCells || {}
        }
      })
      reportStore
        .updateProjectData(projectId, { reportSetting: reportSettings })
    }
  }

  const fetchFileLevel = async (projectId, element) => {
    const REACT_APP_ENDPOINT = apiUrl
    var config = {
      method: 'post',
      url: `${REACT_APP_ENDPOINT}/project/attributereport/${projectId}`,
      headers: {
        Authorization: `Bearer ${commonStore.token}`,
      },
      data: element,
      onDownloadProgress: event => {
        setSheetLoadingPercent(sheetLoadingPercents => [
          ...sheetLoadingPercents,
          { loading: Number(((100 * event.loaded) / event.total).toFixed(2)), index: element.fetchIndex }
        ]);
      }
    }
    const response = await axios(config)
      .then(async f => {
        if (f.status === 200) {
          return f.data
        }
      })
    return response
  }

  const fetchFileObject = async (projectId, object) => {
    const REACT_APP_ENDPOINT = apiUrl
    var config = {
      method: 'post',
      url: `${REACT_APP_ENDPOINT}/calculateObjectInfo/${projectId}`,
      headers: {
        Authorization: `Bearer ${commonStore.token}`,
      },
      data: object,
      onDownloadProgress: event => {
        setSheetLoadingPercent(sheetLoadingPercents => [
          ...sheetLoadingPercents,
          { loading: Number(((100 * event.loaded) / event.total).toFixed(2)), index: object.fetchIndex }
        ]);
      }
    }
    const response = await axios(config)
      .then(async f => {
        if (f.status === 200) {
          return f.data
        }
      })
    return response
  }

  function renderCell(index, g) {
    let column = '';
    while (index >= 0) {
      column = String.fromCharCode((index % 26) + 65) + column;
      index = Math.floor(index / 26) - 1;
    }
    return column + g;
  }

  function columnToIndex(columnStr) {
    let index = 0;
    for (let i = 0; i < columnStr.length; i++) {
      index = index * 26 + (columnStr.charCodeAt(i) - 65 + 1);
    }
    return index - 1;
  }

  function checkIndexCellFromPosition(cell) {
    const columnStr = cell.match(/[A-Z]+/g)[0];
    const rowStr = cell.match(/[0-9]+/g)[0];

    const columnIndex = columnToIndex(columnStr);
    const rowIndex = parseInt(rowStr) - 1;

    return { column: columnIndex, row: rowIndex };
  }

  function isCellRangeValid(cellRange) {
    const pattern = /^[A-Z]+[1-9]\d*:[A-Z]+[1-9]\d*$/;
    return pattern.test(cellRange);
  }


  const renderOperatorTile = (element) => {
    return element?.operator?.type === "summary" ? `[Operator : ${element?.operator?.name}]\n[Attribute  : ${element?.attribute?.name}]` : `[Operator : ${element?.operator?.name}]`;
  };

  const renderFirstTile = (rules) => {
    if (!rules || rules?.length === 0) {
      return 'Data'
    }
    const folderRule = rules.find(c => c?.type === 'folder')
    const attRule = rules.find(c => c?.type === 'attribute')
    if (folderRule && attRule) {
      return `[Folder : ${folderRule?.name}]\n[Attribute  : ${attRule?.name}]`
    }
    if (folderRule) {
      return `[Folder : ${folderRule?.name}]`;
    }
    if (attRule) {
      return `[Attribute : ${attRule?.name}]`;
    }
  }

  const converHeaderSheet = (data) => {
    const defaultHeader = [
      ...renderRuleButton(),
      {
        cell: "A2",
        css: "header",
        format: "common",
        value: data.name,
      }
    ]
    const columns = data?.columns || [];
    for (let i = 0; i < columns.length; i++) {
      const element = columns[i];
      defaultHeader.push({
        css: "headerRules",
        format: "common",
        value: renderOperatorTile(element),
        cell: renderCell(i + 1, 1),// start from row 1, A1 ,B1 ,C1 
      })
      defaultHeader.push({
        css: "header",
        format: "common",
        value: element.name,
        cell: renderCell(i + 1, 2), // start from row 2, A2,B2,C2
      })
    }
    return defaultHeader;
  }

  const converCellSheet = (data) => {
    const _cells = []
    const _sheetdatas = data?.sheetdata || [];
    for (let k = 0; k < _sheetdatas.length; k++) {
      const cells = _sheetdatas[k].cells || [];
      for (let i = 0; i < cells.length; i++) {
        const element = cells[i];
        let c = {
          format: "common",
          value: element?.indent ? `${' '.repeat(element?.indent || 1)}${element.value}` : element.value,
          cell: renderCell(i, k + 2 + 1)
        }
        if (element?.type === 'folder') {
          c.css = "header";
        }
        _cells.push(c)
      }
    }
    return _cells;
  }

  const convertDHXFormat = (oldData) => {
    const newData = {
      sheets: [
        {
          name: oldData.name,
          cols: [
            {
              width: 180,
            }
          ],
          rows: [],
          data: [
            ...converHeaderSheet(oldData),
            ...converCellSheet(oldData)
          ],
        }
      ],
      styles: defaultConfig.styles,
      formats: defaultConfig.formats
    }
    return newData;
  }

  const defaultSheet = (element) => {
    return {
      sheets: [
        {
          data: [
            ...renderRuleButton(),
            ...converHeaderSheet(element)
          ],
          cols: [],
          rows: [],
          name: element?.name || "sheet1",
        }
      ],
      styles: defaultConfig.styles,
      formats: defaultConfig.formats
    }
  }

  const renderRuleButton = () => {
    const { selectedCalculation } = calculationStore;
    if (!selectedCalculation?.value) return;
    const columnLength = (selectedCalculation?.value?.columns?.length || 0)
    const tools = [
      {
        cell: "A1",
        css: "rulesButton",
        format: "common",
        value: renderFirstTile(selectedCalculation?.value?.rules || [])
      },
      // {
      //   cell: renderCell(columnLength + 1, 1),
      //   css: "rulesButton",
      //   format: "common",
      //   value: "Calculation"
      // }
    ]
    return tools;
  }

  const defaultEmptySheet = (element) => {
    const { selectedCalculation } = calculationStore;
    return {
      sheets: [
        {
          data: [
            {
              cell: "A1",
              css: "rulesButton",
              format: "common",
              value: renderFirstTile(selectedCalculation?.value?.rules || [])
            },
            // {
            //   cell: renderCell(1, 1),
            //   css: "rulesButton",
            //   format: "common",
            //   value: "Calculation"
            // }
          ],
          cols: [],
          rows: [],
          name: element?.name || "sheet1",
        }
      ],
      styles: defaultConfig.styles,
      formats: defaultConfig.formats
    }
  }

  const openAddNewCalculation = () => {
    setCalculationEditor({
      open: true,
      store: 'addnewCalculation'
    })
  }

  const openEditCalculation = (index) => {
    setCalculationEditor({
      open: true,
      store: 'editCalculation',
      index
    })
  }

  const openAddNewColumn = (index) => {
    setCalculationEditor({
      open: true,
      store: 'addNewColumn',
      index: calculationStore?.selectedCalculation?.index || 0
    })
  }

  const updateByIndex = async (index, callback, rename, newTemplate, textmessage) => {
    const element = calculationStore.calculationsSettings[index]
    let newData = [...calculationsSettings]
    dashboardStore.setLoadingProgress(true)
    if (element.rules) {
      const queryParams = {
        rowrules: toJS(element.rules),
        columnrules: toJS(element.columns),
        level: element?.level,
        fetchIndex: index,
        loading: (element.loaded / element.total) * 100
      }
      try {
        let u
        if (queryParams.level === "file") {
          u = await fetchFileLevel(projectStore.projectDetail?.id, queryParams)
        }
        if (queryParams.level === "object") {
          u = await fetchFileObject(projectStore.projectDetail?.id, queryParams)
        }
        let c = newTemplate ? newTemplate : { ...newData[index] }
        c.sheetdata = u
        c.isFetching = false
        const convertData = convertDHXFormat(c);
        if (rename) {
          setCalculationsSettings(rename)
        }
        // calculationStore.setAdditionCells(element.additionCells)
        setObjectDatas(c)
        preProcessUserAdditions(c)
        setSheetDatas([{
          ...convertData
        }])
        dashboardStore.setLoadingProgress(false)
        if (callback) {
          callback()
        }
        if(textmessage){
          message.success(t(textmessage))
        }
      }
      catch (error) {
        if (error?.status === 400) {
          setObjectDatas({})
          // calculationStore.setAdditionCells({})
          message.warning(t(error?.data?.message ||'an-error-occurred'))
          // calculationStore.setAdditionCells(element.additionCells)
          setSheetDatas([defaultSheet(element)])
          dashboardStore.setLoadingProgress(false)
          if (callback) {
            callback()
          }
          if (rename) {
            setCalculationsSettings(rename)
          }
          if(textmessage){
            message.success(t(textmessage))
          }
          setObjectDatas(element)
          preProcessUserAdditions(element)
          return
        }
        setObjectDatas({})
        // calculationStore.setAdditionCells({})
        message.error(t('an-error-occurred'))
        // calculationStore.setAdditionCells(element.additionCells)
        setSheetDatas([defaultSheet(element)])
        dashboardStore.setLoadingProgress(false)
        if (callback) {
          callback()
        }
        if (rename) {
          setCalculationsSettings(rename)
        }
        if(textmessage){
          message.success(t(textmessage))
        }
        setObjectDatas(element)
        preProcessUserAdditions(element)
      }
    } else {
      message.warning(t('please-insert-row-rule-to-create-your-report'))
      // calculationStore.setAdditionCells(element.additionCells)
      setSheetDatas([defaultSheet(element)])
      dashboardStore.setLoadingProgress(false)
      setObjectDatas(element)
      preProcessUserAdditions(element)
      if (callback) {
        callback()
      }
      if (rename) {
        setCalculationsSettings(rename)
      }
    }
  }

  const editColumnRules = async (element, data, index, callback) => {
    let newData = [...data]
    dashboardStore.setLoadingProgress(true)
    if (element.rules) {
      const queryParams = {
        rowrules: toJS(element.rules),
        columnrules: toJS(element.columns),
        level: element?.level,
        fetchIndex: index,
        loading: (element.loaded / element.total) * 100
      }
      try {
        let u
        if (queryParams.level === "file") {
          u = await fetchFileLevel(projectStore.projectDetail?.id, queryParams)
        }
        if (queryParams.level === "object") {
          u = await fetchFileObject(projectStore.projectDetail?.id, queryParams)
        }
        let c = { ...newData[index] }
        c.sheetdata = u
        c.isFetching = false
        const convertData = convertDHXFormat(c);
        setCalculationsSettings(data)
        setSheetDatas([{
          ...convertData
        }])
        dashboardStore.setLoadingProgress(false)
        if (callback) {
          callback()
        }
        calculationStore.setSelectedCalculation({
          value: element,
          index
        })
      }
      catch (error) {
        if (error?.status === 400) {
          message.warning( t(error?.data?.message || 'an-error-occurred'))
          setSheetDatas([defaultSheet(element)])
          dashboardStore.setLoadingProgress(false)
          if (callback) {
            callback()
          }
          setCalculationsSettings(data)
          return
        }
        message.error(t('an-error-occurred'))
        setSheetDatas([defaultSheet(element)])
        dashboardStore.setLoadingProgress(false)
        if (callback) {
          callback()
        }
        setCalculationsSettings(data)
      }
    } else {
      message.warning(t('please-insert-row-rule-to-create-your-report'))
      setSheetDatas([defaultSheet(element)])
      dashboardStore.setLoadingProgress(false)
      if (callback) {
        callback()
      }
      setCalculationsSettings(data)
    }
  }

  const addFileRowRule = async (queryParams, newRules, isDeleteAttribute) => {
    dashboardStore.setLoadingProgress(true)
    await reportStore
      .calculateReportingTool(projectStore.projectDetail?.id, queryParams)
      .then(res => {
        let { calculationEditor } = calculationStore
        const sheetIndex = calculationEditor.index || 0
        const temp = [...calculationsSettings]
        temp[sheetIndex].rules = newRules
        temp[sheetIndex].sheetdata = res
        setCalculationsSettings(temp)
        const convertData = convertDHXFormat(temp[sheetIndex]);
        setSheetDatas([{
          ...convertData
        }])
        setObjectDatas(temp[sheetIndex])
        message.success(isDeleteAttribute ? t("remove-column-successfully") : t('add-rule-successfully'))
        saveLastSetting(temp)
        dashboardStore.setLoadingProgress(false)
        setCalculationEditor(false)
      })
      .catch(err => {
        if (err?.status === 400) {
          message.warning( t(err?.data?.message || 'an-error-occurred'))
          dashboardStore.setLoadingProgress(false)
          return
        }
        message.error(t('an-error-occurred'))
        dashboardStore.setLoadingProgress(false)
      })
  }

  const addObjectRowRule = async (queryParams, newRules, isDeleteAttribute) => {
    dashboardStore.setLoadingProgress(true)
    cancelToken = axios.CancelToken.source()
    await reportStore
      .calculateObjectInfo(
        projectStore.projectDetail?.id,
        queryParams,
        cancelToken.token
      )
      .then(res => {
        let { calculationEditor } = calculationStore
        const sheetIndex = calculationEditor.index || 0
        const temp = [...calculationsSettings]
        temp[sheetIndex].rules = newRules
        temp[sheetIndex].sheetdata = res
        setCalculationsSettings(temp)
        const convertData = convertDHXFormat(temp[sheetIndex]);
        setSheetDatas([{
          ...convertData
        }])
        setObjectDatas(temp[sheetIndex])
        message.success(isDeleteAttribute ? t("remove-column-successfully") : t('add-rule-successfully'))
        saveLastSetting(temp)
        dashboardStore.setLoadingProgress(false)
        setCalculationEditor(false)
      })
      .catch(err => {
        if (err?.status === 400) {
          message.warning( t( err?.data?.message || 'an-error-occurred'))
          dashboardStore.setLoadingProgress(false)
          return
        }
        if (err?.status) {
          message.error(t('an-error-occurred'))
        }
        dashboardStore.setLoadingProgress(false)
      })
  }

  const loadTemplateData = () => {
    form.validateFields().then(res => {
      let value = res.calculation
      if (!value) return message.warning(t('please-selected-an-calculation-template'));
      calculationStore.setAdditionCells({})
      const c = calculationsSettings.find(l => l.key === value);
      const index = calculationsSettings.findIndex(l => l.key === value);
      calculationStore.setSelectedCalculation({
        value: c,
        index
      })
      if (index || index === 0) {
        updateByIndex(index)
      } else {
        message.error(t('calculation-not-exist'));
      }
    })
  }

  const handleDeleteSheet = () => {
    let selectedCalculation = calculationStore?.selectedCalculation
    if (!selectedCalculation) return message.warning(t('please-selected-an-calculation-template'))
    const index = selectedCalculation?.index || 0;
    let u = [...calculationsSettings]
    u.splice(index, 1)
    setCalculationsSettings(u)
    saveLastSetting(u)
    setSheetDatas([])
    message.success(t('delete-sheet-data-successfully'))
    form.resetFields()
    calculationStore.setSelectedCalculation(false)
    calculationStore.setAdditionCells({})
    setObjectDatas({})
  }

  const saveCurrentCalculation = () => {
    if (!calculationStore?.selectedCalculation) return message.warning(t('please-selected-an-calculation-template'))
    setCalculationEditor({
      open: true,
      store: 'saveCurrentCalculation',
      index: calculationStore?.selectedCalculation?.index || 0
    })
  }

  const openNewTemplate = () => {
    if (!calculationStore?.selectedCalculation) return message.warning(t('please-selected-an-calculation-template'))
    setCalculationEditor({
      open: true,
      store: 'newTemplate',
      index: calculationStore?.selectedCalculation?.index || 0
    })
  }

  const onCloseModal = () => {
    calculationStore.setSelectedCalculation(false)
    projectStore.setShowDrawerCalculation(false)
    calculationStore.setAdditionCells({})
    setObjectDatas({})
  }

  const findOperator = (type) => {
    let options = [
      { type: 'summary', name: 'Summary' },
      { type: 'quantity', name: 'Quantity' }
    ]
    let operator = options.find(c => c.type === type)
    return operator
  }

  const findProjectCustomAttribute = (id) => {
    const temp = [...projectStore.prjCustomAttributeList]
    let _attribute = temp.find(c => c._id === id)
    return toJS(_attribute)
  }

  const onCancelToken = () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
  }

  const openEditRowRule = () => {
    setCalculationEditor({
      open: true,
      store: 'editRowRules',
      data: calculationStore?.selectedCalculation?.value,
      index : calculationStore?.selectedCalculation?.index
    })
  }

  const openEditColumnRule = (position) => {
    const pos = checkIndexCellFromPosition(position)
    let isValid = isCellRangeValid(position)
    if (isValid) return
    if (pos?.row === 0 && pos?.column) {
      const selectedCalculation = calculationStore?.selectedCalculation?.value;
      const columns = selectedCalculation?.columns || [];
      if (columns?.length < pos?.column) return;
      const columnIndex = pos?.column - 1;
      setCalculationEditor({
        open: true,
        store: 'editColumnRules',
        data: calculationStore?.selectedCalculation,
        position: columnIndex
      })
    }
  }

  const handleAddNewColumn = async (form, onCancelAddNewColumn) => {
    let { calculationEditor } = calculationStore
    const element = calculationsSettings[calculationEditor.index || 0]
    const sheetIndex = calculationEditor.index || 0
    const { rules } = element;
    form.validateFields().then(async response => {
      if (!rules || rules.length <= 0) {
        message.warning(t('please-insert-row-rule-to-create-your-report'))
        return
      }
      const newColumn = {
        name: response.name,
        operator: findOperator(response.operator),
        key: uuid(),
      }
      if (response.attribute) {
        let attribute
        if (element?.level === 'file') {
          attribute = findProjectCustomAttribute(response.attribute)
        }
        if (element?.level === 'object') {
          attribute = {
            type: "attribute",
            name: response.attribute?.label?.props?.title,
            id: response.attribute?.value
          }
        }

        newColumn.attribute = {
          id: attribute?.id,
          name: attribute?.name
        }
      }
      const temp = [...calculationsSettings]
      const newColumns = [...temp[sheetIndex].columns] || []
      newColumns.push(newColumn)
      const queryParams = {
        rowrules: rules,
        columnrules: newColumns,
        level: element?.level
      }
      if (element?.level === 'file') {
        dashboardStore.setLoadingProgress(true)
        await reportStore.calculateReportingTool(projectStore.projectDetail?.id, queryParams).then(res => {
          temp[sheetIndex].columns = newColumns
          temp[sheetIndex].sheetdata = res
          message.success(t("add-rule-successfully"))
          saveLastSetting(temp)
          onCancelToken()
          onCancelAddNewColumn()
          dashboardStore.setLoadingProgress(false)
          setCalculationsSettings(temp)
          const convertData = convertDHXFormat(temp[sheetIndex]);
          preProcessUserAdditions(temp[sheetIndex])
          setSheetDatas([{
            ...convertData
          }])
        })
          .catch(err => {
            if (err?.status === 400) {
              message.error(err?.data?.message || t('an-error-occurred'))
              dashboardStore.setLoadingProgress(false)
              return
            }
            message.error(t('an-error-occurred'))
            dashboardStore.setLoadingProgress(false)
          })
      }
      if (element?.level === 'object') {
        dashboardStore.setLoadingProgress(true)
        cancelToken = axios.CancelToken.source()
        await reportStore.calculateObjectInfo(projectStore.projectDetail?.id, queryParams, cancelToken.token).then(res => {
          temp[sheetIndex].columns = newColumns
          temp[sheetIndex].sheetdata = res
          setCalculationsSettings(temp)
          const convertData = convertDHXFormat(temp[sheetIndex]);
          preProcessUserAdditions(temp[sheetIndex])
          setSheetDatas([{
            ...convertData
          }])
          message.success(t("add-rule-successfully"))
          saveLastSetting(temp)
          onCancelToken()
          onCancelAddNewColumn()
          dashboardStore.setLoadingProgress(false)
        })
          .catch(err => {
            if (err?.status === 400) {
              message.error(err?.data?.message || t('an-error-occurred'))
              dashboardStore.setLoadingProgress(false)
              return
            }
            if (err?.status) {
              message.error(t('an-error-occurred'))
            }
            dashboardStore.setLoadingProgress(false)
          })
      }
    })
  }

  const checkCellChange = (store, data) => {
    const cell = (data?.cell).toString();
    if (!cell) return;
    const additionCells = calculationStore.additionCells ? {...calculationStore.additionCells} : {}
    if (!additionCells[cell]) {
      if (store === 'afterValueChange') {
        additionCells[cell] = {
          value: data.value
        }
      }

      if (store === 'afterStyleChange') {
        additionCells[cell] = {
          style: data.style
        }
      }
    } else {
      if (store === 'afterValueChange') {
        additionCells[cell] = {
          ...additionCells[cell],
          value: data.value
        }
      }

      if (store === 'afterStyleChange') {
        additionCells[cell] = {
          ...additionCells[cell],
          style: data.style
        }
      }
    }
    calculationStore.setAdditionCells(additionCells)
  }

  function findMinColumn(array) {
    if (!Array.isArray(array) || array.length === 0) {
      return 0;
    }
    let minColumn = array[0].column;
    for (let i = 1; i < array.length; i++) {
      if (array[i].column < minColumn) {
        minColumn = array[i].column;
      }
    }
    return minColumn;
  }

  const preProcessUserAdditions = (data = {}) => {
    const _converHeaderSheet = converHeaderSheet(data) || [];
    const _converCellSheet = converCellSheet(data) || [];
    const renderCells = _converHeaderSheet.concat(_converCellSheet);
    const objects = data?.additionCells || {};
    const listAdditions = [];
    for (const property in objects) {
      const isExist = renderCells.find(c => c.cell === property);
      let _object = objects[property];
      if (isExist) {
        //  change style or value
        if ((_object?.value || _object?.value === 0) && isExist?.value !== _object?.value) {
          listAdditions.push({
            cell: property,
            origin: isExist?.value,
            additions: objects[property]
          })
        } else {
          if(!calculationStore.spreadsheet)return ;
          calculationStore.spreadsheet.setStyle(property, objects[property]?.style);
        }
      } else {
        listAdditions.push({
          cell: property,
          origin: undefined,
          additions: objects[property]
        })
      }
    }
    const newAdditions = listAdditions.map(c => {
      const position = checkIndexCellFromPosition(c.cell)
      return {
        ...c,
        ...position
      }
    })
    const firstColumn = findMinColumn(newAdditions);
    const columns = data?.columns?.length || 0;
    const stepMove = columns - firstColumn + 1;
    const additionCells = { ...objects }
    const newObject = {}
    newAdditions.map(c => {
      let _object = additionCells[c.cell]
      const newCell = renderCell((c.column || 0) + stepMove, (c.row || 0) + 1);
      newObject[newCell] = toJS(_object)
      if (_object?.value && calculationStore.spreadsheet) {
        calculationStore.spreadsheet.setValue(newCell, _object.value);
      }
      if (_object?.style && calculationStore.spreadsheet) {
        calculationStore.spreadsheet.setStyle(newCell, _object.style);
      }
    })
    calculationStore.setAdditionCells(newObject)
  }

  const handleSpreadsheet = (spreadsheet) => {
    if (!spreadsheet) return;
    // Apply from API results
    const _converHeaderSheet = converHeaderSheet(objectDatas) || [];
    const _converCellSheet = converCellSheet(objectDatas) || [];
    _converHeaderSheet.forEach(element => {
      spreadsheet.setValue(element.cell, element.value);
    })
    _converCellSheet.forEach(element => {
      spreadsheet.setValue(element.cell, element.value);
    })

    // Apply user additions
    const objects = calculationStore.additionCells ? { ...calculationStore.additionCells } : {};
    for (const property in objects) {
      let _object = objects[property];
      if (_object?.value) {
        spreadsheet.setValue(property, _object.value);
      }
      if (_object?.style) {
        spreadsheet.setStyle(property, _object.style);
      }
    }
  }

  const onExportExcell = () => {
    if(calculationStore.spreadsheet){
      calculationStore.spreadsheet.export.xlsx(calculationStore?.selectedCalculation?.value?.name || 'spreadsheet')
    }
  }


  return (
    <Drawer
      title={
        <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'start' }}>
          <div>
            {t('calculations')}
          </div>
          <Tooltip title={t('commons.help')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            <div>
              <HelpButton helppage={'project_dashboard'} hovered={'black'} />
            </div>
          </Tooltip>
        </div>
      }
      className="projectDashBoardPage"
      mask={false}
      id="projectDashBoardPage"
      width={drawerWidth}
      visible={projectStore.showDrawerCalculation}
      placement="right"
      destroyOnClose={true}
      onClose={onCloseModal}
      zIndex={9998}
    >
      {
        isTabletOrMobileDevice ? (
          projectStore.showDrawerCalculation ? (
            <CustomSplitMobile >
              <Button
                onTouchStart={e => handleTouchStart(e)}
                type="dashed" shape="circle" className="split-panel-mobile">
                <ColumnWidthOutlined />
              </Button>
            </CustomSplitMobile>
          ) : ''
        ) : (
          <div
            onMouseDown={e => handleMouseDown(e)}
            className="split-panel" />
        )
      }
      <Row justify='end'>
        <Form form={form} name="calculation-drawer" layout="horizontal">
          <Form.Item name="calculation">
            <Select
              showSearch
              placeholder={t("select-a-calculation")}
              optionFilterProp="children"
              style={{ width: 200, marginRight: 5 }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={options}
            />
          </Form.Item>
        </Form>
        <Button
          onClick={() => loadTemplateData()}
          type="primary">
          {t('load')}
        </Button>
      </Row>
      <ActionToolDrawer justify="end">
        <Button
          onClick={openAddNewCalculation}
          type="primary">
          {t('commons.new')}
        </Button>
        <Button
          onClick={openNewTemplate}
          type="primary">
          {t('commons.save')}
        </Button>
        <Popconfirm
          className={''}
          title={t("are-you-sure-delete-this-report")}
          placement="rightTop"
          onConfirm={handleDeleteSheet}
          disabled={!calculationStore?.selectedCalculation}
          okText={t('commons.ok')}
          cancelText={t('commons.cancel')}
        >
          <Button
            type="primary"
          >
            {t('commons.delete')}
          </Button>
        </Popconfirm>
        <Button
          style={{ backgroundColor: "#95de64", borderColor: "#95de64" }}
          onClick={onExportExcell}
          type="primary">
            {t('commons.export')}
        </Button>
      </ActionToolDrawer>
      {
        sheetDatas?.length > 0 && (
          <Fragment key={uuid()}>
            <ActionDHXTool>
              <Button
                onClick={openEditRowRule}
                type="primary">
                {t('data')}
              </Button>
              <Button disabled={!calculationStore?.selectedCalculation || calculationStore?.selectedCalculation?.value?.rules?.length === 0} onClick={openAddNewColumn} type="primary"> {t('column')} </Button>
              <Button
                onClick={() => { updateByIndex(calculationStore?.selectedCalculation?.index) }}
                type="primary">
                {t('commons.refresh')}
              </Button>
            </ActionDHXTool>
            <DHTMLXSpreadsheet
              openEditColumnRule={e => openEditColumnRule(e)}
              openEditRowRule={openEditRowRule}
              key={uuid()}
              lastItem={true}
              data={sheetDatas[0]}
              checkCellChange={checkCellChange}
              handleSpreadsheet={handleSpreadsheet}
              calculationStore={calculationStore}
            />
          </Fragment>
        )
      }
      <CalculationEditor
        saveLastSetting={saveLastSetting}
        defaultEmptySheet={defaultEmptySheet}
        updateByIndex={updateByIndex}
        handleAddNewColumn={handleAddNewColumn}
        addFileRowRule={addFileRowRule}
        addObjectRowRule={addObjectRowRule}
        defaultSheet={defaultSheet}
        convertDHXFormat={convertDHXFormat}
        findOperator={findOperator}
        findProjectCustomAttribute={findProjectCustomAttribute}
        editColumnRules={editColumnRules}
        folderList={folderList}
        formSelect={form}
        setObjectDatas={setObjectDatas}
      />
    </Drawer>
  )
}

export default withRouter(
  inject(
    'projectStore',
    'dashboardStore',
    'commonStore',
    'calculationStore',
    'reportStore'
  )(observer(DrawerCalculation))
)
