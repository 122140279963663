import { Checkbox, Tooltip } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import './style.css';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

function TopicReportTable({ topicStore, workflowStore, topicLists, commonStore }) {
    const { t } = useTranslation();
    const [tableWidth, setTableWith] = useState(1710)
    const [phaseLength, setPhaseLength] = useState(0)
    moment.locale(`${commonStore.language}`)

    // get max-index of Topic-phase
    useEffect(() => {
        if (topicLists) {
            let arr = []
            topicLists.map(topic => {
                topic.topicphases.map((phase, index) => {
                    arr.push(index)
                    let maxIndex = Math.max(...arr)
                    setPhaseLength(maxIndex)
                })
            })
        }
    }, [topicLists])

    // set width for .topicReportTable
    useEffect(() => {
        if (phaseLength) {
            setTableWith(tableWidth + (523 * phaseLength))
        }
    }, [phaseLength])


    return (

        <div className="topicReportTable" style={{ width: `${tableWidth}px` }}>

            {/* Topics have workflow */}
            {
                workflowStore.workflowList.map((workflow, index) => {

                    if (workflow.topics.length > 0) return (
                        <table key={index}>
                            <thead>
                                <tr>
                                    {
                                        topicLists.filter(topic => workflow.id === topic.workflow).map((topic, index) => {
                                            return (
                                                // workflow.id === topic.workflow &&
                                                <th className="topicReport-workflowName" key={index} colSpan="100%">
                                                    {workflow.name}
                                                </th>
                                            )
                                        })[0]
                                    }
                                </tr>
                                <tr>
                                    <th className="topic" colSpan="6">{t('topic')}</th>
                                    <th className="data" colSpan="2">{t('data')}</th>
                                    {
                                        topicLists.filter(topic => workflow.id === topic.workflow).map((topic, index) => {
                                            return (
                                                topic.topicphases
                                                    .sort((a, b) => a.order > b.order ? 1 : -1)
                                                    .map((phase, index) => {
                                                        return (
                                                            workflow.id === topic.workflow &&
                                                            <th className="phase" colSpan="3" key={index}>{phase.order + 1}. {phase.name}</th>
                                                        )
                                                    })
                                            )
                                        })[0]
                                    }
                                </tr>
                                <tr>
                                    <th className="topic-title">{t('name')}</th>
                                    <th className="topic-title">{t('description')}</th>
                                    <th className="topic-title">{t('type')}</th>
                                    <th className="topic-title">{t('priority')}</th>
                                    <th className="topic-title">{t('status')}</th>
                                    <th className="topic-title">{t('tags')}</th>
                                    <th className="data-title">{t('folder')}</th>
                                    <th className="data-title">{t('file')}</th>
                                    {
                                        topicLists.filter(topic => workflow.id === topic.workflow).map((topic, index) => {
                                            return (
                                                topic.topicphases.map((phase, index) => {
                                                    return (
                                                        workflow.id === topic.workflow &&
                                                        <React.Fragment key={index} >
                                                            <th className="phase-title">{t('assigned-to')}</th>
                                                            <th className="phase-title">{t('completed')}</th>
                                                            <th className="phase-title">{t('checklist')}</th>
                                                        </React.Fragment>
                                                    )
                                                })
                                            )
                                        })[0]
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    topicLists.map((topic, i) => {
                                        return (
                                            workflow.id === topic.workflow &&
                                            <tr key={i}>
                                                <td className="topic-item topic-name">
                                                    {topic.name}
                                                </td>
                                                <td className="topic-item topic-description">
                                                    {topic.description}
                                                </td>
                                                <td className="topic-item topic-type">
                                                    {t(topic.topictype)}
                                                </td>
                                                <td className="topic-item topic-priority">
                                                    {t(topic.topicpriority)}
                                                </td>
                                                <td className="topic-item topic-status">
                                                    {t(topic.topicstatus)}
                                                </td>
                                                <td className="topic-item topic-tag">
                                                    {
                                                        topic.topictags.map((tag, tagIndex) => {
                                                            return (
                                                                <div className="tag-item" key={tagIndex}>{tag.name}</div>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td className="topic-item topic-folder">
                                                    {
                                                        _.uniqBy(topic.topic3Dobjects, function (e) {
                                                            return e?.model3D?._id && e?.folderTree[0]?.path;
                                                        }).map((oj, i) => {
                                                            return (
                                                                oj?.folderTree?.map((folder, i) => {
                                                                    return (
                                                                        <div key={i}>{folder.path}</div>
                                                                    )
                                                                })
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td className="topic-item topic-file">
                                                    {
                                                        _.uniqBy(topic.topic3Dobjects, function (e) {
                                                            return e?.model3D?._id;
                                                        }).map((oj, i) => {
                                                            return (
                                                                <div key={i}>{oj.model3D.name}</div>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                {
                                                    topic.topicphases
                                                        .sort((a, b) => a.order > b.order ? 1 : -1)
                                                        .map((phase, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <td className="topic-item topic-assign">
                                                                        {
                                                                            phase.assigntopersons.map(user => {
                                                                                return <Tooltip title={(user?.firstName && user?.lastName ?
                                                                                    user.firstName + ' ' + user.lastName : !user?.firstName && user?.lastName ?
                                                                                        user.lastName : user?.firstName && !user?.lastName ?
                                                                                            user.firstName : user.username) + " (" + user.email + ")"} 
                                                                                            placement="right"
                                                                                            overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                                    <div style={{ width: "100%" }}>
                                                                                        <div>
                                                                                            {
                                                                                                user?.firstName && user?.lastName ?
                                                                                                    user.firstName + ' ' + user.lastName : !user?.firstName && user?.lastName ?
                                                                                                        user.lastName : user?.firstName && !user?.lastName ?
                                                                                                            user.firstName : user.username
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            })}
                                                                    </td>
                                                                    <td className="topic-item topic-completed">
                                                                        {
                                                                            (phase.checklists.find(check => !check.isUnCheck) && phase.completedDate)
                                                                            &&
                                                                            moment(phase.completedDate).format('L')
                                                                        }
                                                                    </td>
                                                                    <td className="topic-item topic-checklist">
                                                                        {
                                                                            phase.checklists.map((list, index) => {
                                                                                return (
                                                                                    list.isUnCheck ?
                                                                                        <div className="checklist-item" key={index}><Checkbox className="topicReport-checkList" checked={false} />{list.name}</div> :
                                                                                        <div className="checklist-item" key={index}><Checkbox className="topicReport-checkList" checked={true} />{list.name}</div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                }

                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    )
                })
            }

            {/* Topics do not have workflow */}
            {
                (topicStore.topicList.length > 0) &&
                <table>
                    {
                        // Displayed only when have TopicName and without Workflow 
                        topicStore.topicList.filter(topic => (topic.name && !topic.workflow)).map((topic, index) => {
                            return <thead key={index}>
                                <tr>
                                    <th className="topic" colSpan="6">{t('topic')}</th>
                                    <th className="data" colSpan="2">{t('data')}</th>
                                </tr>
                                <tr>
                                    <th className="topic-title">{t('name')}</th>
                                    <th className="topic-title">{t('description')}</th>
                                    <th className="topic-title">{t('type')}</th>
                                    <th className="topic-title">{t('priority')}</th>
                                    <th className="topic-title">{t('status')}</th>
                                    <th className="topic-title">{t('tags')}</th>
                                    <th className="data-title">{t('folder')}</th>
                                    <th className="data-title">{t('file')}</th>
                                </tr>
                            </thead>
                        })[0]
                    }
                    <tbody>
                        {
                            topicLists.map((topic, index) => {
                                if (topic.name && !topic.workflow) return (
                                    <tr key={index}>
                                        <td className="topic-item topic-name">
                                            {topic.name}
                                        </td>
                                        <td className="topic-item topic-description">
                                            {topic.description}
                                        </td>
                                        <td className="topic-item topic-type">
                                            {t(topic.topictype)}
                                        </td>
                                        <td className="topic-item topic-priority">
                                            {t(topic.topicpriority)}
                                        </td>
                                        <td className="topic-item topic-status">
                                            {t(topic.topicstatus)}
                                        </td>
                                        <td className="topic-item topic-tag">
                                            {
                                                topic.topictags.map((tag, tagIndex) => {
                                                    return (
                                                        <div className="tag-item" key={tagIndex}>{tag.name}</div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td className="topic-item topic-folder">
                                            {
                                                _.uniqBy(topic.topic3Dobjects, function (e) {
                                                    return e?.model3D?._id && e?.folderTree[0]?.path;
                                                }).map((oj, i) => {
                                                    return (
                                                        oj?.folderTree?.map((folder, i) => {
                                                            return (
                                                                <div key={i}>{folder.path}</div>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                        </td>
                                        <td className="topic-item topic-file">
                                            {
                                                _.uniqBy(topic.topic3Dobjects, function (e) {
                                                    return e?.model3D?._id;
                                                }).map((oj, i) => {
                                                    return (
                                                        <div key={i}>{oj.model3D.name}</div>
                                                    )
                                                })
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div>
    )
}

export default inject(
    'topicStore',
    'projectStore',
    'workflowStore',
    'commonStore'
)(observer(TopicReportTable))
