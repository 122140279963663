import { ColumnHeightOutlined, FullscreenExitOutlined, FullscreenOutlined, CloseOutlined, ReloadOutlined, PrinterOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Modal, Select, Spin, Tooltip, Typography, Row, Col } from 'antd';
import * as axios from 'axios';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiUrl } from '../../../../config';
import './style.css';
import TopicReportTable from './TopicReportTable/TopicReportTable';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet, isIOS } from 'react-device-detect';
import HelpButton from '../../../elements/HelpButton';
const { Option } = Select;

const { Text } = Typography;

function DrawerTopicReport(props) {
    const { t } = useTranslation();
    const { topicStore, workflowStore, projectStore, usersStore, projectTeamsStore, commonStore } = props
    moment.locale(`${commonStore.language}`)
    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    const [drawerHeight, setDrawerHeight] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const [screenHeight, setScreenHeight] = useState("0")
    const [time, setTime] = useState("5")
    const [exportModal, setExportModal] = useState(false)
    const timeRefresh = ["5", "10", "30", "60"]
    const [folderPaths, setFolderPaths] = useState([])
    const [topicLists, setTopicLists] = useState([])
    let location = useLocation()
    const { projectId } = useParams();
    const history = useHistory()

    // event for touche split panel
    const handleTouchStart = e => {
        if (e.touches.length !== 1) return;
        if (e.cancelable) {
            e.preventDefault();
        }

        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    }

    useEffect(() => {
        if (location.search === '?topicreport') {
            if (usersStore.currentUser.id && projectId) {
                let checkRoleUser = async () => {
                    await projectStore.findProjectUserRole(projectId).then(res => {
                        let result = res.data
                        let role = result?.role
                        if (role && role !== "project_owner") {
                            onClose()
                            history.push(`/project/${projectId}`)
                        }
                    })
                }
                checkRoleUser()

                return () => {
                    projectStore.clearCurrentUserRoleInProject()
                }
            }
        }
    }, [usersStore.currentUser, projectId])


    const handleRefresh = () => {
        setRefresh(refresh + 1)
    }

    const showExportModal = () => {
        setExportModal(true)
    }

    const closeExportModal = () => {
        setExportModal(false)
    }

    const exportReport = () => axios({
        url: `${apiUrl}/topics/exportExcel?projectId=${projectStore.projectDetail.id}&domain=${window.location.hostname}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('jwt')}`
        }
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'TopicReport.xlsx')
        document.body.appendChild(link)
        link.click()
        setExportModal(false)
    }).catch(error => {
        console.log(error)
        setExportModal(false)
        return
    })

    const handleChangeTimeRefresh = (e) => {
        setTime(e)
    }

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newHeight = (document.body.offsetHeight ? document.body.offsetHeight : 0) - (touch.clientY - (target && target.offsetLeft ? target.offsetLeft : 0));
        let minDrawerHeight = 50;
        if (newHeight < 0) {
            setDrawerHeight(10);
        }
        if (newHeight > minDrawerHeight) {
            setDrawerHeight(newHeight);
        }
        if (newHeight > window.innerHeight) {
            setDrawerHeight(window.innerHeight);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    // event mouse for split panel
    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newHeight = document.body.offsetHeight - (e.clientY - document.body.offsetLeft);
        let minDrawerHeight = 50;
        if (newHeight > minDrawerHeight) {
            setDrawerHeight(newHeight);
        }
        if (newHeight > window.innerHeight) {
            setDrawerHeight(window.innerHeight);
        }
    }, []);

    const handleClickFullScreen = () => {
        if (drawerHeight === window.innerHeight) {
            setDrawerHeight(window.innerHeight / 2);
        } else {
            setDrawerHeight(window.innerHeight);
        }
    }

    // (changeSize) change drawerWidth when drawerWidth > screenWidth
    const handleChangeScreenSize = () => {
        setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

    useEffect(() => {
        if (drawerHeight > screenHeight) {
            setDrawerHeight(screenHeight)
        }
        if (topicStore.isShowDrawerTopicReport) {
            setDrawerHeight(window.innerHeight)
        }
    }, [screenHeight, window.innerHeight, topicStore.isShowDrawerTopicReport])
    // end changeSize

    useEffect(() => {
        if (
            topicStore.isShowDrawerTopicReport &&
            topicLists.length === 0 &&
            location.search === '?topicreport'
        ) {
            const interval = setInterval(() => {
                setRefresh(refresh + 1)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [topicStore.isShowDrawerTopicReport, refresh])

    useEffect(() => {
        if (topicStore.isShowDrawerTopicReport) {
            const populate = async () => {
                topicStore.setLoadingProgress(true)
                await projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
                await workflowStore.getWorkflowList(projectStore.projectDetail.id)
                await topicStore.getTopicList(projectStore.projectDetail.id).then(() => {
                    topicStore.setLoadingProgress(false)
                }).catch(error => {
                    topicStore.setLoadingProgress(false)
                    if (error && error.data && location.search !== '?topicreport') message.error(t(error.data.message))
                })

                // folder tree
                let folders = projectStore.projectDetail.treeData
                setFolderPaths([]) // 
                let getNestedPath = (items, currentPath = '', store = [{ id: '', path: '' }]) => {
                    const isArray = Array.isArray(items);
                    if (isArray) {
                        items.forEach((item) => {
                            if (!item.children) { // return soon

                                if (currentPath) {
                                    store.push(
                                        //   key, fileName, currentPath
                                        { id: item.modelId, path: currentPath }
                                    );
                                }
                                return
                            }
                            let path = currentPath
                            path += '/' + item.title

                            getNestedPath(item.children, path, store)

                        });
                    }
                }
                getNestedPath(folders, '', folderPaths)

                let _topicLists = topicStore.topicList
                if (_topicLists) {
                    _topicLists = _topicLists.map(item => {
                        return {
                            id: item.id,
                            name: item.name,
                            percentCompleted: item.percentCompleted,
                            project: item.project,
                            description: item.description,
                            topicpriority: item.topicpriority,
                            topicstatus: item.topicstatus,
                            topictype: item.topictype,
                            topicphases: item.topicphases.map(phase => {
                                return {
                                    id: phase.id,
                                    isActive: phase.isActive,
                                    name: phase.name,
                                    completedDate: phase.completedDate,
                                    createdAt: phase.createdAt,
                                    order: phase.order,
                                    topic: phase.topic,
                                    workflowsphase: phase.workflowsphase,
                                    assigntopersons: phase.assigntopersons.map(person => {
                                        return {
                                            blocked: person.blocked,
                                            confirmed: person.confirmed,
                                            createdAt: person.createdAt,
                                            email: person.email,
                                            id: person.id,
                                            organization: person.organization,
                                            provider: person.provider,
                                            role: person.role,
                                            topicdiscussion: person.topicdiscussion,
                                            updatedAt: person.updatedAt,
                                            username: person.username,
                                            firstName: person.firstName,
                                            lastName: person.lastName
                                        }
                                    }),
                                    checklists: phase.checklists.map(list => {
                                        return {
                                            createdAt: list.createdAt,
                                            id: list.createdAt,
                                            isUnCheck: list.isUnCheck,
                                            name: list.name,
                                            order: list.order,
                                            updatedAt: list.updatedAt,
                                            workflowsphasechecklist: list.workflowsphasechecklist,
                                        }
                                    }),
                                }
                            }),
                            topictags: item.topictags.map(tag => {
                                return {
                                    id: tag.id,
                                    name: tag.name,
                                    project: tag.project,
                                    topic: tag.topic,
                                }
                            }),
                            topic3Dobjects: item.topic3Dobjects?.map(object => {
                                return {
                                    id: object.id,
                                    GUID: object.GUID,
                                    createdAt: object.createdAt,
                                    name: object.name,
                                    topic: object.topic,
                                    updatedAt: object.updatedAt,
                                    model3D: {
                                        _id: object.model3D.id,
                                        name: object.model3D.name
                                    },
                                    folderTree: folderPaths?.filter(f => f.id === object.model3D.id).map(f => {
                                        return {
                                            path: f.path,
                                            id: f.id,
                                        }
                                    })
                                }
                            }),
                            updatedAt: item.updatedAt,
                            user: item.user,
                            workflow: item.workflow,
                            _id: item._id,
                        }
                    })
                }

                setTopicLists(_topicLists)
            }
            populate()
        }
    }, [topicStore.isShowDrawerTopicReport, refresh, time])

    useEffect(() => {
        if (location.search === '?topicreport') {
            const topicReportAutoOpen = () => {
                setDrawerHeight(window.innerHeight)
                topicStore.setIsShowDrawerTopicReport(true)
            }
            topicReportAutoOpen()
        }
    }, [time])

    // auto refresh
    useEffect(() => {
        if (time === '5' && location.search === '?topicreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 5)); // 5 miniutes 
            return () => {
                clearInterval(interval)
            }

        }

        if (time === '10' && location.search === '?topicreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 10)); // 10 miniutes 
            return () => {
                clearInterval(interval)
            }
        }

        if (time === '30' && location.search === '?topicreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 30)); // 30 miniutes 
            return () => {
                clearInterval(interval)
            }
        }

        if (time === '60' && location.search === '?topicreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 60)); // 60 miniutes 
            return () => {
                clearInterval(interval)
            }
        }
    }, [time, refresh])



    const onClose = () => {
        topicStore.setIsShowDrawerTopicReport(false)
        topicStore.clearTopicList()
        setDrawerHeight(0)
        setRefresh(0)
    }

    const CloseIcon = () => {
        return (
            <Row gutter={[8]}>
                {location.search !== "?topicreport" && <Col className="">
                    {drawerHeight === window.innerHeight ? (
                        <Tooltip title={(isMobile || isTablet) ? '' : t('out-full-screen')} placement="bottom">
                            <div>
                                <FullscreenExitOutlined
                                    onClick={() => handleClickFullScreen()}
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip title={(isMobile || isTablet) ? '' : t('full-screen')} placement="bottom">
                            <FullscreenOutlined onClick={() => handleClickFullScreen()} />
                        </Tooltip>
                    )}
                </Col>}
                <Col>
                    <Tooltip title={(isMobile || isTablet) ? '' : t('commons.close')} placement="bottom">
                        <div>
                            <CloseOutlined onClick={() => onClose()} />
                        </div>
                    </Tooltip>
                </Col>
            </Row>
        )
    }

    return (
        <Drawer
            closeIcon={<CloseIcon />}
            title={
                <div className="topicReport-outer">
                    <div className="help-btn-wrapper">
                        {t('topic-report')}
                        <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <div>
                                <HelpButton helppage={'topic_report'} hovered={'black'} />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={`topicReport-btn-wrapper ${isIOS ? 'ios-device' : ''}`}>
                        <div className={`topicReport-btn-outer ${(isMobile || isTablet) && 'minimize'}`}>
                            <Button onClick={handleRefresh} icon={(isMobile || isTablet) && <ReloadOutlined />}>{!(isMobile || isTablet) && t('commons.refresh')}</Button>
                            <Button className="print-btn" icon={(isMobile || isTablet) && <PrinterOutlined />}>{!(isMobile || isTablet) && t('commons.print')}</Button>
                            <Button className="export-btn" onClick={showExportModal} icon={(isMobile || isTablet) && <FileTextOutlined />}>{!(isMobile || isTablet) && t('commons.export')}</Button>
                        </div>
                    </div>
                </div>
            }
            placement="bottom"
            closable={location.search !== "?topicreport" ? true : false}
            height={drawerHeight}
            //onClose={location.search !== "?topicreport" ? onClose : false}
            visible={topicStore.isShowDrawerTopicReport}
            className="drawerTopicReport custom-wraper-splitPanel"
            mask={false}
        >
            {topicStore.isShowDrawerTopicReport && (isTabletOrMobileDevice ? (
                <div className="splitpanel-horizontal-mobile">
                    <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                        <ColumnHeightOutlined />
                    </Button>
                </div>
            ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-horizontal" />)
            )}
            {
                topicStore.topicList.length > 0 ?
                    <div className="topicReport-header">
                        <Text className="topicReport-projectName"><span>{projectStore.projectDetail.name}</span> ({moment(projectStore.projectDetail.createdAt).format('L')})</Text>
                        <div className="special-link">
                            {
                                location.search === '?topicreport' &&
                                <div>
                                    <Link to={location.pathname} target="_blank" className="link-to-project" >{t('link-to-project')}</Link>
                                </div>
                            }
                            {
                                location.search === '?topicreport' ?
                                    <div>
                                        <Text>{t('automatic-refresh')}: </Text>
                                        <Select
                                            defaultValue={"5 minutes"}
                                            style={{ width: 120 }}
                                            onChange={handleChangeTimeRefresh}
                                        >
                                            {
                                                timeRefresh.map((time, index) => {
                                                    return (
                                                        <Option key={index} value={time}>{time} {'minutes'}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                    :
                                    <Link to={`${location.pathname}?topicreport`} target="_blank" className="link-to-project" >{t('open-this-report-as-html-page-without-xd-twin-3d-view')}</Link>
                            }
                        </div>
                    </div> : ""
            }
            {
                topicStore.isLoading ?
                    <Spin size="large" className="topicReport-spiner" /> : <TopicReportTable topicLists={topicLists} />
            }
            <Modal
                title={t('commons.export') + " XLSX"}
                visible={exportModal}
                zIndex={9999}
                okText={t('commons.ok')}
                cancelText={t('commons.cancel')}
                onOk={exportReport}
                onCancel={closeExportModal}
            >
                <p>{t('do-you-want-to-export-this-report-to-xlsx-file')}</p>
            </Modal>
        </Drawer>
    );
}

export default inject(
    'topicStore',
    'projectStore',
    'workflowStore',
    'usersStore',
    'projectTeamsStore',
    'commonStore'
)(observer(DrawerTopicReport))
