import { useEffect, useRef, useState } from "react";
import { DHTMLXSpreadsheetWrapper } from "./style";
import { toJS } from "mobx";

const dhx = window.dhx;

export default function DHTMLXSpreadsheet({ 
  data, lastItem, openEditRowRule, openEditColumnRule  ,checkCellChange ,handleSpreadsheet ,calculationStore
 }) {
  let container = useRef(null);
  let [spreadsheet, setSpreadsheet] = useState(null);

  useEffect(() => {
    const rowLimit = 20; // Set the desired number of rows
    const spreadsheet = new dhx.Spreadsheet(container.current, {
      rowsCount: rowLimit, // number of rows
      colsCount: rowLimit,
      menu : true
    });
    setSpreadsheet(spreadsheet);
    return () => spreadsheet.destructor();
  }, []);

  const renderStyle = () => {
    let style = { width: "100%", height: "100%", paddingBottom: "20px", maxHeight: '700px' };
    if (lastItem) {
      delete style.paddingBottom;
    }
    return style;
  }

  useEffect(() => {
    if (!spreadsheet || !data) return;
    spreadsheet.parse({ ...data });

    function eventHandler(event, arg) {
      let position = "";
      for (let i = 0; i < arg.length; i++) {
        position = (arg[i])
      }
      if (event === 'afterSelectionSet') {
        if (position === 'A1') {
          openEditRowRule()
        } else {
          openEditColumnRule(position)
        }
      }
    };
    handleSpreadsheet(spreadsheet)
    spreadsheet.events.on("afterSelectionSet", function () {
      eventHandler('afterSelectionSet', arguments);
    });

    spreadsheet.events.on("afterValueChange", function (cell, value) {
      checkCellChange("afterValueChange",{ cell, value })
    });
    
    spreadsheet.events.on("afterStyleChange", function (cell, value) {
      checkCellChange("afterStyleChange",{ cell, style: value })
    });
    
    // spreadsheet.events.on("afterColumnDelete", function (cell) {
    //   console.log(cell);
    // });

    calculationStore.setSpreadsheet(spreadsheet)

    return () => {
      spreadsheet.events.detach('afterSelectionSet');
      calculationStore.setSpreadsheet(false)
    };
  }, [spreadsheet, data]);

  return <DHTMLXSpreadsheetWrapper ref={container} style={renderStyle()} />;
}
