import React, { useEffect, useState, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { FeedbackAnswerWrapper, FeedbackHeader, FeedbackContent, FeedbackAnserTextarea } from './style'
import { Button, Drawer, Checkbox, Input, Radio, Select, Skeleton, Form, message, Popconfirm, Image, Descriptions, Tooltip } from 'antd'
import { useMediaQuery } from 'react-responsive'
import validator from '../../../../validator'
import { ColumnWidthOutlined } from '@ant-design/icons';
import { Math as CesiumMath, Cartographic } from 'cesium'
import { getCurrentCamera, getCenterScreenCoordinates, getUserHeightFromGround } from '../../../helper/CesiumUtils'
import moment from 'moment'
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/es";
import "moment/locale/sv";
import "moment/locale/vi";
import uuid from 'uuid'
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'
import HelpButton from '../../../elements/HelpButton'
const { Option } = Select;
const { TextArea } = Input;

const DrawerFeedbackAnswer = ({ projectStore, commonStore, usersStore, feedbackStore, viewer }) => {
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)
    const [form] = Form.useForm();
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (document.body.offsetWidth ? document.body.offsetWidth : 0) - (touch.clientX - (target && target.offsetLeft ? target.offsetLeft : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if(newWidth > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minDrawerWidth = 360;
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if(newWidth > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);
    //#endregion

    useEffect(() => {
        if (feedbackStore.shownFeedbackAnswer.show) {
            setDrawerWidth(isTabletOrMobile ? 300 : 350)
            feedbackStore.clearFeedbackFormDetail()
            form.resetFields()
            if (feedbackStore.shownFeedbackAnswer.action === 'add') {
                feedbackStore.setShowAddLocationControl(true)
                if (feedbackStore.shownFeedbackAnswer.isGenericFeedback) {
                    const getGenericFeedback = async () => {
                        setLoading(true)
                        await feedbackStore.getGenericFeedback(projectStore.projectDetail.id)
                        setLoading(false)
                    }
                    getGenericFeedback()
                } else {
                    feedbackStore.setFeedbackFormDetail(feedbackStore.shownFeedbackAnswer.data)
                    feedbackStore.setLoadingProgress(false)
                }
            } else if (feedbackStore.shownFeedbackAnswer.action === 'edit') {


            } else if (feedbackStore.shownFeedbackAnswer.action === 'view') {
                feedbackStore.setFeedbackFormDetail(feedbackStore.shownFeedbackAnswer.data)
                feedbackStore.setLoadingProgress(false)
            }
        }
    }, [feedbackStore.shownFeedbackAnswer])

    useEffect(() => {
        feedbackStore.feedbackFormDetail?.isPublic && !usersStore.currentUser?.id && feedbackStore.setShowAddLocationControl(true)
    }, [feedbackStore.feedbackFormDetail, usersStore.currentUser])
    


    /**
     * get all model
     * @param {} viewer 
     */
    const explitiFromSample = async (viewer) => {
        var modelList = []
        var primitives = viewer.scene.primitives;
        for (var i = 0; i < primitives.length; i++) {
            var _tileset = primitives.get(i)
            var model = projectStore.findModelByUrl(_tileset?.resource?.url)
            if (model && !model.isRender) {
                modelList.push(_tileset)
            }
        }
        return modelList;
    }

    const onSendFeedbackAnswer = () => {
        form.validateFields().then(async values => {
            let _arrayValues = Object.entries(values).map(([key, value]) => ({ key, value }));
            let _arrformcontrol = feedbackStore.feedbackFormDetail.formControlData.elements.map(item => {
                return ({ ...item, value: _arrayValues.find(x => x.key === item.name)?.value, dynamicFieldId: uuid() })
            })
            let _viewlocation
            let _cameraLocation
            let _modellist = await explitiFromSample(viewer.current.cesiumElement)
            let _centerScreenCoordinates = getCenterScreenCoordinates(viewer.current.cesiumElement)
            if (feedbackStore.shownFeedbackAnswer.isGenericFeedback) {
                _cameraLocation = getCurrentCamera(viewer)
            } else {
                let _data = feedbackStore.feedbackFormDetail.viewLocation
                _cameraLocation = _data.camData
            }
            let _lat = 0, _lon = 0
            if (_centerScreenCoordinates) {
                _lat = _centerScreenCoordinates.cartographic.latitude * (180 / Math.PI)
                _lon = _centerScreenCoordinates.cartographic.longitude * (180 / Math.PI)
            } else {
                let _cartographic = Cartographic.fromCartesian(_cameraLocation.position)
                _lat = _cartographic.latitude * (180 / Math.PI)
                _lon = _cartographic.longitude * (180 / Math.PI)
            }
            let _userHeightFromGround = await getUserHeightFromGround(viewer.current.cesiumElement, _modellist, _lon, _lat)

            _viewlocation = {
                cameraLocation: _cameraLocation,
                cameraTargetLocation: feedbackStore.locationControlData && feedbackStore.locationControlData.position && feedbackStore.locationControlData.position.x ? feedbackStore.locationControlData : (_centerScreenCoordinates ? _centerScreenCoordinates : ''),
                userHeightFromGround: _userHeightFromGround.height ? _userHeightFromGround.height : '',
                cemeraHeight: viewer.current.cesiumElement.camera.positionCartographic.height,
                objectGUID: ''
            }

            let _fbanwer = {
                title: feedbackStore.feedbackFormDetail.title,
                description: feedbackStore.feedbackFormDetail.description,
                isGenericFeedback: feedbackStore.feedbackFormDetail.isGenericFeedback,
                formControlData: { ...feedbackStore.feedbackFormDetail.formControlData, elements: _arrformcontrol, isFeedbackAnswerForPublicUser: !usersStore?.currentUser?.id ? true : false,},
                assignTo: feedbackStore.feedbackFormDetail.assignTo,
                isPublic: feedbackStore.feedbackFormDetail.isPublic,
                viewLocation: _viewlocation,
                timestamp: new Date().getTime(),
                project: projectStore.projectDetail.id,
                user: usersStore.currentUser.id,
                feedbackform: feedbackStore.feedbackFormDetail.id,
            }

            feedbackStore.setLoadingProgress(true)
            feedbackStore.createFeedbackAnswer(_fbanwer).then(async (res) => {
                //Add to list public feedback answer to display at the same session
                if(!usersStore?.currentUser?.id && res?.data){
                    feedbackStore.setPublicFeedbackAnswer([...feedbackStore.publicFeedbackAnswers,res.data]);
                }
                // check add to decidim
                if (projectStore.projectDetail.decidimConnector && projectStore.projectDetail.decidimConnector.projectId === projectStore.projectDetail._id && projectStore.projectDetail.decidimConnector.isActived) {
                    let content_answer = `Comment from 6DPlanner project: ${projectStore.projectDetail.name} ${moment(new Date()).format("DD.MM.YYYY HH:mm")}<br>`
                    let arrAnswers = res.data?.formControlData?.elements || []
                    arrAnswers.map(c => {
                        if (c.value !== null) {
                            if (c.className === 'smileyButton') {
                                let _value = ''
                                if (c.value.split('.')[0] === "/static/media/smiley1") {
                                    _value = "Very happy"
                                } else if (c.value.split('.')[0] === "/static/media/smiley2") {
                                    _value = "Happy"
                                } else if (c.value.split('.')[0] === "/static/media/smiley3") {
                                    _value = "Neutral"
                                } else if (c.value.split('.')[0] === "/static/media/smiley4") {
                                    _value = "Unhappy"
                                } else {
                                    _value = "Very unhappy"
                                }
                                content_answer += ` ${c.title}: ${_value} <br/>`
                            } else {
                                if (!['textReadonly', 'urlReadonly', 'image'].includes(c.className)) {
                                    content_answer += ` ${c.title}: ${c.value} <br/>`
                                }
                            }
                        }
                    })

                    await feedbackStore.decidimConnect({
                        url: projectStore.projectDetail.decidimConnector.url,
                        email: projectStore.projectDetail.decidimConnector.email,
                        password: projectStore.projectDetail.decidimConnector.password,
                        isDecrypt: false
                    }).then(async (response) => {
                        const data = {
                            url: projectStore.projectDetail.decidimConnector.url,
                            jwt_token: response.data.jwt_token,
                            content: content_answer
                        }
                        await feedbackStore.decidimAddComment(data).catch(err => {
                            message.warning(t('save-connector-failed'))
                        })
                    }).catch(err => {
                        message.warning(t('save-connector-failed'))
                    })
                }


                form.resetFields()
                message.success(t('feedback-created-successfull'))
                onCloseFeedbackAnswer()
                feedbackStore.setLoadingProgress(false)
                feedbackStore.setShowAddLocationControl(false)
                feedbackStore.getFeedbackAnserList(projectStore.projectDetail.id, (feedbackStore?.feedbackFormDetail?.isPublic && 'public') || undefined).then(res => {
                    feedbackStore.setFeedbackVisualization(res.data)
                })
            }).catch(() => {
                form.resetFields()
                message.error(t('create-feedback-answer-failed'))
                onCloseFeedbackAnswer()
                feedbackStore.setLoadingProgress(false)
            })
        })
    };
    const onUpdateFeedbackAnswer = () => {
        form.validateFields().then(async values => {
            const {feedbackFormDetail} = feedbackStore;
            let _arrayValues = Object.entries(values).map(([key, value]) => ({ key, value }));
            let _arrformcontrol = feedbackStore.feedbackFormDetail.formControlData.elements.map(item => {
                return ({ ...item, value: _arrayValues.find(x => x.key === item.name)?.value, dynamicFieldId: uuid() })
            })
            let _viewlocation
            let _cameraLocation
            let _modellist = await explitiFromSample(viewer.current.cesiumElement)
            let _centerScreenCoordinates = getCenterScreenCoordinates(viewer.current.cesiumElement)
            if (feedbackStore.shownFeedbackAnswer.isGenericFeedback) {
                _cameraLocation = getCurrentCamera(viewer)
            } else {
                let _data = feedbackStore.feedbackFormDetail.viewLocation
                _cameraLocation = _data.camData
            }
            let _lat = 0, _lon = 0
            if (_centerScreenCoordinates) {
                _lat = _centerScreenCoordinates.cartographic.latitude * (180 / Math.PI)
                _lon = _centerScreenCoordinates.cartographic.longitude * (180 / Math.PI)
            } else {
                let _cartographic = Cartographic.fromCartesian(_cameraLocation.position)
                _lat = _cartographic.latitude * (180 / Math.PI)
                _lon = _cartographic.longitude * (180 / Math.PI)
            }
            let _userHeightFromGround = await getUserHeightFromGround(viewer.current.cesiumElement, _modellist, _lon, _lat)

            _viewlocation = {
              cameraLocation: _cameraLocation,
              cameraTargetLocation:
                feedbackStore.locationControlData &&
                feedbackStore.locationControlData.position &&
                feedbackStore.locationControlData.position.x
                  ? feedbackStore.locationControlData
                  : (
                    feedbackFormDetail?.viewLocation?.cameraTargetLocation?.position?.x ? feedbackFormDetail?.viewLocation?.cameraTargetLocation : (_centerScreenCoordinates ? _centerScreenCoordinates: '')
                  ),
              userHeightFromGround: _userHeightFromGround.height
                ? _userHeightFromGround.height
                : '',
              cemeraHeight:
                viewer.current.cesiumElement.camera.positionCartographic.height,
              objectGUID: '',
            }
            let _fbanwer = {
                title: feedbackStore.feedbackFormDetail.title,
                description: feedbackStore.feedbackFormDetail.description,
                isGenericFeedback: feedbackStore.feedbackFormDetail.isGenericFeedback,
                formControlData: { ...feedbackStore.feedbackFormDetail.formControlData, elements: _arrformcontrol, isFeedbackAnswerForPublicUser: !usersStore?.currentUser?.id ? true : false, },
                assignTo: feedbackStore.feedbackFormDetail.assignTo,
                isPublic: feedbackStore.feedbackFormDetail.isPublic,
                viewLocation: _viewlocation,
                timestamp: new Date().getTime(),
                project: projectStore.projectDetail.id,
                user: usersStore.currentUser.id,
                // feedbackform: feedbackStore.feedbackFormDetail.id,
            }
            feedbackStore.setLoadingProgress(true)
            feedbackStore.updateFeedbackAnswer(feedbackStore.feedbackFormDetail.id, _fbanwer).then(async (res) => {
                //Update to list public feedback answer to display at the same session
                if(!usersStore?.currentUser?.id){
                    const index = feedbackStore.publicFeedbackAnswers.find( c => c.id === feedbackStore.feedbackFormDetail.id);
                    if(index !== -1){
                        let _arr = [...feedbackStore.publicFeedbackAnswers]
                        _arr[index] = {
                            ...res,
                            ..._fbanwer
                        };
                        feedbackStore.setPublicFeedbackAnswer(_arr)
                    }
                }
                // check add to decidim
                if (projectStore.projectDetail.decidimConnector && projectStore.projectDetail.decidimConnector.projectId === projectStore.projectDetail._id && projectStore.projectDetail.decidimConnector.isActived) {
                    let content_answer = `Comment from 6DPlanner project: ${projectStore.projectDetail.name} ${moment(new Date()).format("DD.MM.YYYY HH:mm")}<br>`
                    let arrAnswers = res?.formControlData?.elements || []
                    arrAnswers.map(c => {
                        if (c.value !== null) {
                            if (c.className === 'smileyButton') {
                                let _value = ''
                                if (c.value.split('.')[0] === "/static/media/smiley1") {
                                    _value = "Very happy"
                                } else if (c.value.split('.')[0] === "/static/media/smiley2") {
                                    _value = "Happy"
                                } else if (c.value.split('.')[0] === "/static/media/smiley3") {
                                    _value = "Neutral"
                                } else if (c.value.split('.')[0] === "/static/media/smiley4") {
                                    _value = "Unhappy"
                                } else {
                                    _value = "Very unhappy"
                                }
                                content_answer += ` ${c.title}: ${_value} <br/>`
                            } else {
                                if (!['textReadonly', 'urlReadonly', 'image'].includes(c.className)) {
                                    content_answer += ` ${c.title}: ${c.value} <br/>`
                                }
                            }
                        }
                    })

                    await feedbackStore.decidimConnect({
                        url: projectStore.projectDetail.decidimConnector.url,
                        email: projectStore.projectDetail.decidimConnector.email,
                        password: projectStore.projectDetail.decidimConnector.password,
                        isDecrypt: false
                    }).then(async (response) => {
                        const data = {
                            url: projectStore.projectDetail.decidimConnector.url,
                            jwt_token: response.data.jwt_token,
                            content: content_answer
                        }
                        await feedbackStore.decidimAddComment(data).catch(err => {
                            message.warning(t('save-connector-failed'))
                        })
                    }).catch(err => {
                        message.warning(t('save-connector-failed'))
                    })
                }


                form.resetFields()
                message.success(t('feedback-updated-successfull'))
                onCloseFeedbackAnswer()
                feedbackStore.setLoadingProgress(false)
                feedbackStore.setShowAddLocationControl(false)
                feedbackStore.getFeedbackAnserList(projectStore.projectDetail.id).then(res => {
                    feedbackStore.setFeedbackVisualization(res.data)
                })
            }).catch(() => {
                form.resetFields()
                message.error(t('update-feedback-answer-failed'))
                onCloseFeedbackAnswer()
                feedbackStore.setLoadingProgress(false)
            })
        })
    };

    const onCloseFeedbackAnswer = () => {
        form.resetFields()
        feedbackStore.clearShowFeedbackAnswer();
        feedbackStore.clearFeedbackFormDetail();
        feedbackStore.setShowAddLocationControl(false)
        setDrawerWidth(0)
        setLoading(false)
    };

    const checkAndDisableInput = () => {
        return feedbackStore.shownFeedbackAnswer.action === 'view' && usersStore?.currentUser?.id;
    }

    const deleteFeedbackHandler = () => {
        feedbackStore.setLoadingProgress(true)
        feedbackStore.deleteFeedbackAnswers(feedbackStore.feedbackFormDetail.id).then(async (res) => {
            form.resetFields()
            onCloseFeedbackAnswer()
            feedbackStore.setLoadingProgress(false)
            feedbackStore.getFeedbackAnserList(projectStore.projectDetail.id).then(res => {
                feedbackStore.setFeedbackVisualization(res.data)
            })
            message.success(t('feedback-deleted-successfully'))
        }).catch(() => {
            form.resetFields()
            message.error(t('delete-feedback-failed'))
            onCloseFeedbackAnswer()
            feedbackStore.setLoadingProgress(false)
        })
    }

    const FeedbackFormData = ({ formData ,feedbackFormDetail, index }) => {
        const elementSkeleton = () => {
            switch (formData.className) {
                case 'singleLine':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) },
                                    { validator: validator.validateEmptyString }
                                ]}
                            >
                                <Input
                                    className={checkAndDisableInput() ? 'readOnly' : undefined}
                                    minLength={formData.minLength}
                                    maxLength={formData.maxLength === 0 ? 524288 : formData.maxLength}
                                    placeholder={formData.placeHolder}
                                />
                            </Form.Item>
                        </Form.Item>
                    )
                case 'multiLine':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) },
                                    { min: formData.minLength || 0, message: t('please-enter-minimum-minlength-charactors', { minlength: formData.minLength }) },
                                    { max: formData.maxLength === 0 ? 524288 : formData.maxLength, message: t('please-enter-maximum-maxlength-charactors', { maxlength: formData.maxLength }) },
                                    { validator: validator.validateEmptyString }
                                ]}
                            >
                                <FeedbackAnserTextarea
                                    readOnly={checkAndDisableInput()}
                                    rows={4}
                                    minLength={formData.minLength}
                                    maxLength={formData.maxLength === 0 ? 524288 : formData.maxLength}
                                    placeholder={formData.placeHolder}
                                />
                            </Form.Item>
                        </Form.Item>
                    )
                case 'email':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { type: 'email', message: t('email-is-not-valid') },
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) },
                                    { validator: validator.validateEmptyString }
                                ]}
                            >
                                <FeedbackAnserTextarea
                                    readOnly={checkAndDisableInput()}
                                    rows={2}
                                    minLength={0}
                                    maxLength={formData.maxLength ? formData.maxLength : 524288}
                                    placeholder={formData.placeHolder}
                                />
                            </Form.Item>
                        </Form.Item>
                    )
                case 'dropdown':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) }
                                ]}
                            >
                                <Select placeholder={formData.placeHolder} className={checkAndDisableInput() ? 'readOnly' : undefined}>
                                    {formData.choices.map(choice =>
                                        <Option key={choice} value={choice}>{choice}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Form.Item>
                    )
                case 'radioGroup':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) }
                                ]}
                            >
                                <Radio.Group
                                    style={
                                        formData.direction === 'horizontal'
                                            ? null : {
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }
                                    }
                                    className={checkAndDisableInput() ? 'readOnly' : undefined}>
                                    {formData.choices.map(choice =>
                                        <Radio key={choice} value={choice}>{choice}</Radio>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                    )
                case 'checkboxGroup':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) }
                                ]}
                            >
                                <Checkbox.Group
                                    style={
                                        formData.direction === 'horizontal'
                                            ? null : {
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }
                                    }
                                    options={formData.choices.slice()}
                                    className={checkAndDisableInput() ? 'readOnly' : undefined} />
                            </Form.Item>
                        </Form.Item>
                    )
                case 'smileyButton':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                name={formData.name}
                                noStyle
                                initialValue={formData.value}
                                rules={[
                                    { required: formData.isRequired, message: t('please-input-title', { title: formData.title }) }
                                ]}
                            >
                                <Radio.Group
                                    style={
                                        formData.direction === 'horizontal'
                                            ? null : {
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }
                                    }
                                    className="smiley-button"
                                    disabled={checkAndDisableInput()}
                                >
                                    {formData.choices.map(choice =>
                                        <Radio.Button key={choice} value={choice}><img src={choice} /></Radio.Button>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                    )
                case 'urlReadonly':
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                initialValue={formData.value}
                            >
                                {
                                    formData.value && (
                                        <Descriptions.Item >
                                            <a href={formData.value || '#'} rel="noopener noreferrer" target="_blank">{formData.text || formData.value}</a>
                                        </Descriptions.Item>
                                    )
                                }
                            </Form.Item>
                        </Form.Item>
                    )
                case 'textReadonly':
                    return (
                        <Form.Item label={formData.title}>
                            <Form.Item
                                initialValue={formData.description}
                            >
                                {
                                    formData.description && (
                                        <Descriptions.Item >
                                            {formData.description}
                                        </Descriptions.Item>
                                    )
                                }
                            </Form.Item>
                        </Form.Item>
                    )
                case 'image':
                    let imgElement ;
                    if(feedbackFormDetail?.feedbackform?.formControlData?.elements?.length > 0){
                        imgElement = feedbackFormDetail.feedbackform.formControlData.elements[index];
                    }
                    return (
                        <Form.Item label={formData.title}>
                            {
                                formData.description === '' ? null : <p className="description-explain">{formData.description}</p>
                            }
                            <Form.Item
                                initialValue={imgElement ? imgElement?.value : formData.value}
                            >
                                {
                                    (formData.value || imgElement?.value ) && (
                                        <Image
                                            width={drawerWidth - 48}
                                            src={`${imgElement?.value || formData.value}`}
                                            preview={false}
                                        />
                                    )
                                }
                            </Form.Item>
                        </Form.Item>
                    )
                default:
                    break
            }
        }

        return (<>{elementSkeleton()}</>)
    }

    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t("feedback-answer")}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={'feedback_answer'} hovered={'black'}/>
                        </div>
                    </Tooltip>
                </div>
            }
            placement="right"
            width={drawerWidth}
            closable={true}
            onClose={e => onCloseFeedbackAnswer(false)}
            visible={feedbackStore.shownFeedbackAnswer.show}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            forceRender
            zIndex={9999}
            bodyStyle={{ paddingBottom: 80 }}
            className="feedback-answer-drawer custom-wraper-splitPanel"
            footer={
                <div style={{ textAlign: 'right' }}>
                    {
                        projectStore.isExistLicenses && (
                            <Button style={{ display: feedbackStore.shownFeedbackAnswer.action === 'view' ? 'none' : 'inline-block', marginRight: 8 }} onClick={e => onSendFeedbackAnswer()} type="primary">
                                {t("send")}
                            </Button>
                        )
                    }
                    {
                        projectStore.projectDetail.isPublic && !usersStore?.currentUser?.id &&
                        (<Button style={{ display: feedbackStore.shownFeedbackAnswer.action === 'view' ? 'inline-block' : 'none', marginRight: 8 }} onClick={e => onUpdateFeedbackAnswer()} type="primary">
                            {t("update")}
                        </Button>)
                    }
                    {
                        usersStore?.currentUser?.id && (
                            <Popconfirm
                        placement={'topLeft'}
                        okText={t('commons.delete')} cancelText={t('commons.cancel')} okType={'danger'} onConfirm={deleteFeedbackHandler}
                        title={t('are-you-sure-you-want-to-delete-this-feedback')}
                    >
                        <Button danger style={{ display: feedbackStore.shownFeedbackAnswer.action === 'view' ? 'inline-block' : 'none', marginRight: 8 }}>
                            {t("commons.delete")}
                        </Button>
                    </Popconfirm>
                        )
                    }
                    <Button onClick={e => onCloseFeedbackAnswer(false)}>
                        {feedbackStore.shownFeedbackAnswer.action === 'view' ? t('commons.close') : t('commons.cancel')}
                    </Button>
                </div>
            }
        >
            <FeedbackAnswerWrapper>
                {isTabletOrMobile ? (
                    feedbackStore.shownFeedbackAnswer.show ? (
                        <div className="splitpanel-mobile">
                            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                                <ColumnWidthOutlined />
                            </Button>
                        </div>
                    ) : ""
                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel" />)
                }
                <Skeleton loading={loading} active>
                    <FeedbackHeader>
                        <span className='title'>{feedbackStore.feedbackFormDetail.title}</span>
                        <span className='datetime' style={{ display: feedbackStore.shownFeedbackAnswer.action === 'view' ? 'block' : 'none' }}>{moment(feedbackStore.feedbackFormDetail?.cloneData?.createdAt ? feedbackStore.feedbackFormDetail?.cloneData?.createdAt : feedbackStore.feedbackFormDetail.createdAt).format('lll')}</span>
                        <span className='description-tile'>{t('description')}</span>
                        <div className='description'>{feedbackStore.feedbackFormDetail.feedbackform ? feedbackStore.feedbackFormDetail.feedbackform.description : feedbackStore.feedbackFormDetail.description}</div>
                    </FeedbackHeader>
                    <FeedbackContent>
                        <Form form={form} name="feedback-answer-form" layout="vertical">
                            {
                                feedbackStore.feedbackFormDetail.formControlData && feedbackStore.feedbackFormDetail.formControlData.elements.map((elem, index) => (
                                    !elem.isDelete && (
                                        <FeedbackFormData feedbackFormDetail={feedbackStore.feedbackFormDetail} key={index} index={index} formData={elem} />
                                    )
                                ))
                            }
                        </Form>
                    </FeedbackContent>
                </Skeleton>
            </FeedbackAnswerWrapper>
        </Drawer>
    )
}

export default inject(
    'projectStore',
    'usersStore',
    'feedbackStore',
    'commonStore'
)(observer(DrawerFeedbackAnswer));